import React from "react";
import Navbar from "react-bootstrap/Navbar";
import { Container } from "react-bootstrap";
import Nav from "react-bootstrap/Nav";
import { Link } from "react-router-dom";
import Image from "react-bootstrap/Image";
import { TravelExplore } from "@mui/icons-material";
import { Contacts } from "@mui/icons-material";
import EarthIcon from "../assets/svg/earthlogo.svg";
import CRMIcon from "../assets/svg/cloudCrm.svg";
import UserSettingLogo from "../assets/svg/UserSetting.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useToast } from "../Context/ToastContext";
import { useApplication } from "../hooks/UseApplication";
import { Box } from "@material-ui/core";

const Sidebar = ({ children, isOpen, isClosed }) => {
  const [visible, setVisible] = useState(false);
  const sidebarClass = isOpen ? "sidebar open" : "sidebar";
  const { logout } = useApplication();
  const toaster = useToast();

  const handleLogout = async () => {
    toaster.notifySuccess("Déconnécté avec succès");
    await logout();
  };

  /**** Custom variable style */
  const bluedark = "#3F50B6";
  const stickySidebar = {
    maxWidth: "203px",
    with: "100%",
  };
  const fontWeightTitle = {
    fontWeight: "600",
  };
  const large = {
    width: "250px",
    height: "100vh",
    zIndex: 99999,
  };
  const normal = {
    width: "230px",
    height: "100vh",
    zIndex: 99999,
  };

  const handleToggle = () => {
    setVisible((current) => !current);
  };

  return (
    <>
      <aside
        className={sidebarClass + (visible ? " " : " closed")}
        style={isOpen ? normal : large}
      >
        <Navbar
          className="d-block px-3"
          variant="dark"
          style={Object.assign(
            {
              width: "100%",
              height: "100%",
              paddingTop: "30px",
              backgroundColor: bluedark,
            },
            stickySidebar
          )}
        >
          <Box className="d-flex flex-column h-100">
            <Box style={{ height: "inherit" }}>
              <Navbar.Brand className="pb-0 m-0 text-center d-flex justify-content-xl-center justify-content-lg-center justify-content-md-center justify-content-sm-between  justify-content-between">
                <Nav.Link as={Link} to="/">
                  <h3 style={fontWeightTitle}>MIXDATA</h3>
                </Nav.Link>
                <CloseIcon
                  className="d-xl-none d-lg-none d-md-none d-sm-block d-block pointer"
                  onClick={handleToggle}
                />
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar id="responsive-navbar-nav" className="d-block mt-5">
                <Nav className="d-block mb-2">
                  <Nav.Link
                    className="text-white d-flex align-items-center"
                    as={Link}
                    to="/map"
                    onClick={handleToggle}
                  >
                    <Box className="me-2">
                      <TravelExplore
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          width: "18px",
                        }}
                      />
                    </Box>
                    Toutes les parcelles
                  </Nav.Link>
                </Nav>
                <Nav className="d-block mb-2">
                  <Nav.Link
                    className="text-white d-flex align-items-center"
                    as={Link}
                    to="/my-land"
                    onClick={handleToggle}
                  >
                    <Image className="fluid me-2" src={EarthIcon}></Image>
                    Mes parcelles
                  </Nav.Link>
                </Nav>
                <Nav className="d-block mb-2">
                  <Nav.Link
                    className="text-white d-flex align-items-center"
                    as={Link}
                    to="/list-crm"
                    onClick={handleToggle}
                  >
                    <Box className="me-2">
                      <Contacts
                        style={{
                          color: "#fff",
                          fontSize: "18px",
                          width: "18px",
                        }}
                      />
                    </Box>
                    Mes contacts
                  </Nav.Link>
                </Nav>
                <Nav className="d-block mb-2">
                  <Nav.Link
                    className="text-white d-flex align-items-center"
                    as={Link}
                    to="/user"
                    onClick={handleToggle}
                  >
                    <Image className="fluid me-2" src={UserSettingLogo}></Image>
                    Gestion utilisateur
                  </Nav.Link>
                </Nav>
              </Navbar>
            </Box>
            <Box>
              <Button
                variant="none"
                type="button"
                onClick={handleLogout}
                style={{ color: "#fff", fontWeight: "501", background: "none" }}
              >
                Se déconnecter
              </Button>
            </Box>
          </Box>
        </Navbar>
      </aside>
      <Container className="d-none">{children}</Container>
    </>
  );
};

export default Sidebar;
