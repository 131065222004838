import React, {useEffect, useRef, useState} from "react";
import mapboxgl, {FullscreenControl, NavigationControl} from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';
 import jwtInterceptor from "../../service/jwtInterceptor";
import {LAND_ENDPOINTS} from "../../utils/api";

import ReactDOMServer from "react-dom/server";
import ParcelResume from "../parcelResume/ParcelResume";
import {Box, CircularProgress} from "@mui/material";
import {StyleSwitcherControl} from "../../service/map/StyleSwitcherControl";
import IconPng from '../../assets/Images/icons/285659_marker_map_icon.png'
import StarIconPng from '../../assets/Images/icons/marker-star-yellow.png'
import {landService} from "../../service/LandService";

const LandsMapByMapBox = ({filters, onMapReady, myLandsFilter, onShowResume, mapDataRef}) => {
    //MAPBOX URL/SOURCES/...
    const emptyGeoJson = {
        type: 'FeatureCollection',
        features: []
    };
    const RESULT_SEARCH_SOURCE_ID = "search_response_source"
    const RESULT_SEARCH_POLYGONS = "search_response_polygons_layers"
    const MY_LANDS_POLYGONS = "mylands_polygons_layers"
    const RESULT_MY_LANDS_SEARCH_POLYGONS_SOURCE_ID = "my_lands_search_result_source"
    const RESULT_MY_LANDS_SEARCH_POLYGONS = "my_lands_search_result"
    const SEARCH_PAGE_SIZE = 500

    const [searchLoadingState, setSearchLoadingState] = useState(false)

    const searchLoadingRef = useRef(false);

    const setSearchLoadingRefAndState = (value) => {
        setSearchLoadingState(value)
        searchLoadingRef.current = value;
    };

    const mapRef = useRef(null);
    const mapContainerRef = useRef()
    const center = [8.2275, 46.8182] // geneve
    const zoom = 7.7
    const zoomSearchMax = 16
    const [mapLoaded, setMapLoaded] = useState(false)
    const [viewState, setViewState] = useState({
        center: center,
        zoom: zoom
    });
    const [myLands, setMyLands] = useState(null)
    const [isMyLandsSourceDone, setIsMyLandsSourceDone] = useState(false)
    const [showResume, setShowResume] = useState(false);
    const [selectedLandIdResume, setSelectedLandIdResume] = useState();
    const [activeFilter, setActiveFilter] = useState({isEmpty: true})
    const [activePopup, setActivePopup] = useState(undefined)


    useEffect(() => {

        onFilter(filters)
    }, [filters]);
    useEffect(() => {

        if(myLandsFilter.tag) {

            onFilterByTag(myLandsFilter.tag)
        }
    }, [myLandsFilter]);

    useEffect(() => {
        mapboxgl.accessToken = 'pk.eyJ1Ijoic2lvb2thbWl4ZGF0YSIsImEiOiJjbTZ0ZDI1NDQwMXJpMmxyM3Z4eTZ4ejU3In0.zKpFruvjFh3Qf9mSrsnKyQ'
        mapRef.current = new mapboxgl.Map({
            container: mapContainerRef.current,
            ...viewState
        });

        // Appliquer un style pour que le canvas prenne 100% de la largeur
        const setupCanvasSize = () => {
            const canvasElements = document.querySelectorAll('.mapboxgl-canvas');
            if (canvasElements.length > 0) {
                canvasElements.forEach(canvas => {
                    canvas.style.width = '100%';
                });
            }
        };

        // Fonction pour redimensionner la carte
        const handleResize = () => {
            mapRef.current.resize();
            setupCanvasSize();
        };

        // Ajouter un écouteur d'événement pour le redimensionnement de la fenêtre
        window.addEventListener('resize', handleResize);

        mapRef.current.on('load', () => {
            setupCanvasSize();
            mapRef.current.addControl(new FullscreenControl(), "bottom-left")
            mapRef.current.addControl(new NavigationControl(), "bottom-right")
            mapRef.current.addControl(new StyleSwitcherControl(), "top")
        })

        mapRef.current.on('style.load', () => {
            setupCanvasSize();
            setMapLoaded(true)
            onMapReady && onMapReady();

            // Ajout de l'écouteur de zoom
            mapRef.current.on('zoom', () => {
                const currentZoom = mapRef.current.getZoom();
                console.log('Niveau de zoom actuel:', currentZoom.toFixed(2));
            });

            restrictToSwitzerland(mapRef.current)
            mapRef.current.loadImage(IconPng, (error, image) => {
                if (error) throw error;
                if (!mapRef.current.hasImage('marker-icon-custom')) mapRef.current.addImage('marker-icon-custom', image);
            });
            mapRef.current.loadImage(StarIconPng, (error, image) => {
                if (error) throw error;
                if (!mapRef.current.hasImage('star-icon-custom')) mapRef.current.addImage('star-icon-custom', image);
            });
            buildDefaultPolygons(mapRef.current)
            fetchMyLands()
            createSearchSourceAndLayers(mapRef.current)
        })

        return () => {
            window.removeEventListener('resize', handleResize);
            mapRef.current.remove()
        }
    }, [])

    const restrictToSwitzerland = (map) => {
        map.addSource('country_boundaries_source', {
            type: 'vector',
            url: 'mapbox://mapbox.country-boundaries-v1',
        });

        map.addLayer({
            id: "all_countries",
            type: "fill",
            source: 'country_boundaries_source',
            "source-layer": 'country_boundaries'
        })

        // Appliquer un filtre sur la couche 'admin-1-boundaries' pour griser tous les pays
        map.setFilter('all_countries', ['!=', 'iso_3166_1', 'CH']); // 'CH' est le code ISO de la Suisse

        // Changer la couleur des autres pays en gris
        map.setPaintProperty('all_countries', 'fill-color', '#d3d3d3');
        map.setPaintProperty('all_countries', 'fill-opacity', 0.8);

    }

    const buildDefaultPolygons = (map) => {
        map.addSource('default_polygons', {
            type: 'vector',
            url: 'mapbox://siookamixdata.tileset-lands-v4',
            minzoom: 0,
            maxzoom: 22,
            cluster: false
        });

        map.addLayer({
            id: "all_lands",
            type: "fill",
            source: 'default_polygons',
            "source-layer": 'mixdata-lands',
            minzoom: 11,
            maxzoom: 22,
            paint: {
                'fill-opacity': 0.5,
                'fill-color': "#3384e9"

            }
        })

        map.addLayer({
            id: "all_lands_lines",
            type: "line",
            source: 'default_polygons',
            "source-layer": 'mixdata-lands',
            minzoom: 10,
            maxzoom: 22,
            paint: {
                'line-opacity': 1,
                'line-width': 0.5,
                'line-color': "#fff"

            }
        })

        mapRef.current.on('click', 'all_lands', (landEvent) => attachPopup(landEvent));

    }

    const createSearchSourceAndLayers = (map) => {
        map.addSource(RESULT_SEARCH_SOURCE_ID, {
            type: 'geojson',
            data: emptyGeoJson,
            clusterMaxZoom: 22,
            clusterRadius: 40
        });

        map.addLayer({
            id: RESULT_SEARCH_POLYGONS,
            type: "fill",
            source: RESULT_SEARCH_SOURCE_ID,
            minzoom: 0,
            maxzoom: 22,
            paint: {
                'fill-opacity': 0.4,
                'fill-color': "#ee2a2a"

            }
        })


        map.addSource(RESULT_MY_LANDS_SEARCH_POLYGONS_SOURCE_ID, {
            type: 'geojson',
            data: emptyGeoJson,
            clusterMaxZoom: 22,
            clusterRadius: 40
        });

        map.addLayer({
            id: RESULT_MY_LANDS_SEARCH_POLYGONS,
            type: "fill",
            source: RESULT_MY_LANDS_SEARCH_POLYGONS_SOURCE_ID,
            minzoom: 0,
            maxzoom: 22
        })
    }

    const fetchMyLands = () => {
        jwtInterceptor
            .post(`${LAND_ENDPOINTS.searchMyLands}`, {
                size: 1000,
                page: 0,
                toGeoJson: true
            })
            .then((res) => {
                setMyLands(res.data.data.results);
                buildMyLandLayer(res.data)
            })
            .catch((error) => {
                console.error("error in useEffect");
            });
    }

    const searchMyLands = (filters) =>{
        return jwtInterceptor
            .post(`${LAND_ENDPOINTS.searchMyLands}`, {
                ...filters,
                size: 1000,
                page: 0,
                toGeoJson: true
            })
    }

    const buildMyLandLayer = (myLands) => {

        if(mapRef?.current && myLands?.data?.results && !isMyLandsSourceDone) {
            console.log('inside build myLands layer',  myLands)
            mapRef.current.addSource('mylands_polygons', {
                type: 'geojson',
                data: myLands.data.results,
                minzoom: 0,
                maxzoom: 22
            });

            mapRef.current.addLayer({
                id: MY_LANDS_POLYGONS,
                type: "fill",
                source: 'mylands_polygons',
                minzoom: 0,
                maxzoom: 22,
                paint: {
                    'fill-opacity': 0.6,
                    'fill-color': "#00b03e"
                }
            })

            mapRef.current.addLayer({
                id: 'favorites-my-lands-layer',
                type: 'symbol',
                source: "mylands_polygons",
                filter: ['==', ['get', 'isFavorite'], true],
                minzoom: 10,
                layout: {
                    'icon-image': 'star-icon-custom',
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'text-allow-overlap': true
                }
            });

            console.log("favorites")

            setIsMyLandsSourceDone(true)

        }
    }

    const onShowDetails = (land_id) => {
        setShowResume(true);
        setSelectedLandIdResume(land_id);
    }

    const attachPopup = (land) => {
        if(mapRef.current) {
            // Fermer MapData de manière explicite
            if (mapDataRef && mapDataRef.current) {
                mapDataRef.current.closeWindow();
            }

            // Appeler onShowResume pour s'assurer que le résumé s'affiche
            if (onShowResume) {
                onShowResume();
            }

            const popup = new mapboxgl.Popup()
                .setLngLat(land.lngLat)
                .setHTML(ReactDOMServer.renderToString(getBubbleInfo(land.features[0].properties)))
                .addTo(mapRef.current);

            // Afficher le résumé de la parcelle
            onShowDetails(land.features[0].properties.land_id);
            setActivePopup(popup);
        }
    }

    const fitBoundsToSearchResponse = (map) => {
        const data = map.getSource(RESULT_SEARCH_SOURCE_ID)._data;
        const features = data?.features ?? []

        if (features.length === 0) {
            console.warn("No features found in the layer");
            return;
        }

        const bounds = new mapboxgl.LngLatBounds();

        features.forEach(feature => {
            if (feature.geometry.type === "Polygon" || feature.geometry.type === "MultiPolygon") {
                let center = []
                if(feature.properties.geoCenter) {
                    center = feature.properties.geoCenter
                } else {
                    center = feature.geometry.coordinates.flat(2)
                    center = center.slice(0, 2)
                }
                bounds.extend(center);
            } else {
                const coordinates = feature.geometry.coordinates;
                bounds.extend(coordinates);
            }
        });

        if(bounds.isEmpty()) {
            map.setZoom(zoom)
            map.setCenter(center)
        } else {
            // Si une seule parcelle
            if (features.length === 1) {
                const surface = features[0].properties.surface ? parseFloat(features[0].properties.surface) : 0;
                let maxZoom = 18;

                // Ajuster le zoom en fonction de la surface
                if (surface > 10000) { // Plus de 10 000 m²
                    maxZoom = 16;
                }

                map.fitBounds(bounds, {
                    padding: 50,
                    duration: 1000,
                    maxZoom: maxZoom
                });
            }
            // Si plusieurs parcelles
            else {
                map.fitBounds(bounds, {
                    padding: 50,
                    duration: 1000,
                    maxZoom: 16
                });
            }
        }
    }

    const getBubbleInfo = (featuresProperties) => {
        return (
            <div>
                <div>
                    <span>N° parcelle : </span> {featuresProperties.numParcelle}
                </div>
                <div>
                    <span>Commune : </span> {featuresProperties.commune}
                </div>
                <div>
                    <span>Canton : </span> {featuresProperties.canton}
                </div>
                <div>
                    <span>Adresse : </span> {featuresProperties.adresse}
                </div>
                <div>
                    <span>Nbr de batiment : </span> {featuresProperties.nbrBatiment}
                </div>
                <div>
                    <span>Année de construction : </span> {featuresProperties.anneeConstruction}
                </div>
                <div>
                    <span>Surface : </span> {featuresProperties.surface}
                </div>

            </div>
        )
    }

    const reinitializeCenter = (map) => {
        //reinitialize center
        mapRef.current.setCenter(center)
        mapRef.current.setZoom(zoom)
    }

    const reinitializeSearchResult = () => {
        if(mapRef.current) {
            reinitializeCenter(mapRef.current)
            mapRef.current.getSource(RESULT_SEARCH_SOURCE_ID).setData(emptyGeoJson)

        }
    }

    const onFilter = (filters) => {
        console.log('inside onFilter')
        if(!filters) return
        //hide popup and widget
        if(activePopup) activePopup.remove()
        setShowResume(false)
        setSelectedLandIdResume(undefined)
        onShowResume && onShowResume() // Fermer MapData

        setActiveFilter(filters)
        if (filters.isEmpty && (mapRef?.current && mapLoaded)) {
            mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0.8)
            mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0.8)

            if(!mapRef?.current.getSource(RESULT_SEARCH_SOURCE_ID)) {
                mapRef?.current.getSource(RESULT_SEARCH_SOURCE_ID).setData(emptyGeoJson)
            }
            reinitializeSearchResult()
        } else if(!filters.isEmpty && mapRef?.current && mapLoaded) {
            //hide default lands
            mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0)
            mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0)
            setMyLandSearchResultData(emptyGeoJson, mapRef.current)

            searchLands(filters, mapRef.current).then(response => {
                fitBoundsToSearchResponse(mapRef.current)
            })
        }
    }

    const searchLands = async (filters, map) => {
        if(!map.getSource(RESULT_SEARCH_SOURCE_ID)) {
            createSearchSourceAndLayers(map)
        }

        reinitializeSearchResult()
        return searcLandsPartially(filters, map)
    }

    const searcLandsPartially = async (filters, map) => {
        setSearchLoadingRefAndState(true)
        reinitializeCenter(mapRef?.current)

        let totalResult = 100
        const size = SEARCH_PAGE_SIZE
        let page = 0
        let isFirst = true

        const doHaveNext = (totalResult, size, nextPage) => {
            const totalPages = Math.ceil(totalResult / size); // Calculate total pages
            return nextPage < totalPages;
        }

        while(isFirst || (doHaveNext(totalResult, size, page) && searchLoadingRef?.current === true)){

            const searchBody = {...filters, page: page, size: size, toGeoJson: true, geoJsonGeometryType: 'Polygon'}
            const data = await landService.searchLands(searchBody)
            appendResponseToResultData(data.results, map)

            totalResult = data.total
            isFirst = false
            page++

        }

        stopSearch()
        fitBoundsToSearchResponse(map)

    }

    const stopSearch = () => {
        console.log('stopping search')
        setSearchLoadingRefAndState(false)
        if(mapRef?.current && mapRef.current.getSource(RESULT_SEARCH_SOURCE_ID)) {
            fitBoundsToSearchResponse(mapRef.current)
        }
    }

    const setMyLandSearchResultData = (resultData, map) => {
        map.getSource(RESULT_MY_LANDS_SEARCH_POLYGONS_SOURCE_ID).setData(resultData)
    }

    const appendResponseToResultData = (resultData, map) => {
        let currentData = map.getSource(RESULT_SEARCH_SOURCE_ID)._data

        if(currentData) {
            const mergedFeatures = currentData.features.concat(resultData.features)
            currentData = {...currentData, features: mergedFeatures}
        }else {
            currentData = resultData
        }

        map.getSource(RESULT_SEARCH_SOURCE_ID).setData(currentData)
    }

    const toggleResume = () => {
        setShowResume((prevShowResume) => !prevShowResume);
    };

    const onFilterByTag = (tag) => {
        if(mapRef?.current) {
            console.log('Filtering by tag:', tag)
            reinitializeSearchResult()

            mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0)
            mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0)

            searchMyLands({tag: [tag._id]}).then(response => {
                if (response.data && response.data.data && response.data.data.results) {
                    const results = response.data.data.results;

                    if (results.features && results.features.length > 0) {
                        setMyLandSearchResultData(results, mapRef.current)
                        mapRef.current.setPaintProperty(RESULT_MY_LANDS_SEARCH_POLYGONS, 'fill-opacity', 0.5)
                        mapRef.current.setPaintProperty(RESULT_MY_LANDS_SEARCH_POLYGONS, 'fill-color', tag.bgColorHex)

                        const bounds = new mapboxgl.LngLatBounds();
                        results.features.forEach(feature => {
                            if (feature.geometry.type === 'Polygon' || feature.geometry.type === 'MultiPolygon') {
                                const coordinates = feature.geometry.coordinates;
                                let center;

                                if (coordinates[0] && coordinates[0][0]) {
                                    center = coordinates[0][0];
                                } else {
                                    center = [8.2275, 46.8182];
                                }

                                bounds.extend(center);
                            }
                        });

                        if (!bounds.isEmpty()) {
                            // Si une seule parcelle, zoom très rapproché
                            if (results.features.length === 1) {
                                mapRef.current.fitBounds(bounds, {
                                    padding: 20,
                                    duration: 1000
                                });

                                // Attendre la fin de l'animation de fitBounds puis ajuster au zoom exact
                                setTimeout(() => {
                                    const targetCenter = [(bounds.getEast() + bounds.getWest()) / 2, (bounds.getNorth() + bounds.getSouth()) / 2];
                                    mapRef.current.easeTo({
                                        center: targetCenter,
                                        zoom: 18.93,
                                        duration: 1000
                                    });
                                }, 1100);
                            }
                            // Si plusieurs parcelles, utiliser zoomSearchMax
                            else {
                                mapRef.current.fitBounds(bounds, {
                                    padding: 20,
                                    duration: 1000,
                                    maxZoom: zoomSearchMax
                                });
                            }
                        }
                    } else {
                        console.warn('Aucune parcelle trouvée pour le tag:', tag.name)

                        mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0.8)
                        mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0.8)

                        alert(`Aucune parcelle trouvée pour le tag "${tag.name}"`)
                    }
                } else {
                    console.warn('Pas de résultats pour ce tag')

                    mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0.8)
                    mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0.8)

                    alert(`Aucune parcelle trouvée pour le tag "${tag.name}"`)
                }
            }).catch(error => {
                console.error('Erreur lors de la recherche par tag:', error)

                mapRef.current.setPaintProperty('all_lands', 'fill-opacity', 0.8)
                mapRef.current.setPaintProperty(MY_LANDS_POLYGONS, 'fill-opacity', 0.8)

                alert(`Erreur lors de la recherche pour le tag "${tag.name}"`)
            })
        }
    }

    return (
        <>
            {searchLoadingState &&
                <div className="bg-white d-flex p-2 rounded" style={{zIndex: 9999, position : 'absolute', bottom: '10px', left: '50%', transform: 'translateX(-50%)'}}>
                    <div className="mx-2">
                        <CircularProgress size={15} color="secondary"/>
                    </div>
                    <a style={{cursor: 'pointer'}} className="text-decoration-none" onClick={() => stopSearch()}>
                        <span className="fs-6" >Arrêter la recherche</span>
                    </a>
                </div>
            }
            <div
                id='map-container'
                style={{width:'100%', height:'100vh'}}
                ref={mapContainerRef}
                className="mapbox-container"
            />
            <style jsx="true">{`
                .mapbox-container .mapboxgl-canvas {
                    width: 100% !important;
                }
            `}</style>
            <Box className={`resume ${showResume ? "open" : "closed"}`}>

                <ParcelResume
                    closeFenetre={toggleResume}
                    landId={selectedLandIdResume}
                />
            </Box>
        </>
    )
};


export default LandsMapByMapBox;
