import React, { useEffect, useState, useRef } from "react";
import Paper from "@material-ui/core/Paper";
import { Box } from "@mui/material";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LandsMapFilterCard } from "../component/LandsMapFilterCard";
import { Helmet } from "react-helmet";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ViewList } from "@mui/icons-material";
import CustomBtn from "../component/customBtns/CustomBtn";
import LandsMapByMapBox from "../component/maps/LandsMapByMapBox";
import MapData from "../component/mapDatas/mapDatas";

const MapsContainer = (props) => {
  const [loading, setLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [mapBoxFilters, setMapBoxFilters] = useState({isEmpty: true});
  const [isMapReady, setIsMapReady] = useState(false);
  const location = useLocation();
  const mapDataRef = useRef(null);

  const [myLandsFilter, setMyLandsFilter] = useState({})

  const [filters, setFilters] = useState({
    landsCodeNumber: "",
    commune: "",
    canton: "",
    address: "",
    isEmpty: true
  });

  const mapsLayout = {
    display: "block",
    height: "100%",
    width: "100%",
    position: "absolute",
  };

  const switchBtn = {
    position: "fixed",
    top: "2%",
    right: "1%",
    zIndex:9999
  };

  useEffect(() => {
    if (location.state) {
      console.log("Navigation state received:", location.state);
      const initialFilters = {
        landsCodeNumber: location.state.landsCodeNumber || "",
        commune: location.state.commune || "",
        canton: location.state.canton || "",
        address: location.state.address || "",
        isEmpty: false
      };
      setFilters(initialFilters);
    }
  }, [location.state]);

  useEffect(() => {
    // Appliquer overflow hidden au body quand le composant est monté
    document.body.style.overflow = "hidden";
    
    // Restaurer le style original du body quand le composant est démonté
    return () => {
      document.body.style.overflow = "";
    };
  }, []);

  const handleSearch = (filterParam) => {
    console.log("Handling search with filters:", filterParam);
    if (isMapReady) {
      setMapBoxFilters(filterParam);
    } else {
      console.log("Map not ready yet, search will be queued");
      setFilters(filterParam);
    }
  }

  const onTagFilter = (tag) => {
    setMyLandsFilter({tag: tag})
  }

  const handleMapReady = () => {
    console.log("Map is ready");
    setIsMapReady(true);

    setTimeout(() => {
      if (!filters.isEmpty) {
        console.log("Force applying filters:", filters);
        setMapBoxFilters({...filters});
      }
    }, 500);
  };

  const handleShowResume = () => {
    if (mapDataRef.current) {
      mapDataRef.current.closeWindow();
    }
  };

  return (
      <Box className="w-100" sx={{height: "101vh", position: "relative"}}>
        <Box className="maps-layout" sx={mapsLayout}>
          <LandsMapByMapBox
              filters={mapBoxFilters}
              onMapReady={handleMapReady}
              myLandsFilter={myLandsFilter}
              onShowResume={handleShowResume}
              mapDataRef={mapDataRef}
          />
          <MapData ref={mapDataRef} onTagFilter={onTagFilter} />
          <Nav.Link as={Link} to="/land" state={filters}>
            <CustomBtn
                label="Liste"
                bgColor="#299CDB"
                iconBg={"#3ba9e5"}
                icon={<ViewList/>}
                styles={{float: "right", ...switchBtn}}
            />
          </Nav.Link>
        </Box>
        <Helmet>
          <title>Maps - Mixdata</title>
        </Helmet>
        <Grid container spacing={2}
              sx={{height: "100%", position: isError || loading ? 'relative' : 'revert', zIndex: '99999999999'}}>
          <Grid
              className="filters shadow-none"
              xl={3}
              sx={{position: "absolute"}}
          >
            <Paper className="shadow-none m-4" style={{height: "auto"}}>
              <LandsMapFilterCard
                  handleSearch={handleSearch}
                  defaultFilters={filters}
                  isMapReady={isMapReady}
              />
            </Paper>
          </Grid>
        </Grid>
      </Box>
  );
};

export default MapsContainer;