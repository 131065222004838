import {tagService} from "../../service/crm/TagService";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import React from "react";

export const DeletetagDialog = ({tagId, isModalOpen, onClose}) => {
    const handleCloseDetachTag = () => {
        onClose(false)
    }

    const handleConfirmDetachTag = async () => {
        onClose(await tagService.deleteTagById(tagId))
    }

    return (
        <Dialog
            open={isModalOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                Détacher un tag
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Voulez vous vraiment supprimer le tag ?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseDetachTag}>Annuler</Button>
                <Button onClick={handleConfirmDetachTag} autoFocus>Confirmer</Button>
            </DialogActions>
        </Dialog>
    )
}