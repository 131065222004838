import jwtInterceptor from "../jwtInterceptor";
import {LANDS_INTERACTIONS_ENDPOINTS} from "../../utils/api";

class LandsInteractionsService {

    getLands() {
        return jwtInterceptor.get(LANDS_INTERACTIONS_ENDPOINTS.getLands).then((response) => {
            return response.data;
        });
    }

    updateFavorite(externalId){
        return jwtInterceptor.post(LANDS_INTERACTIONS_ENDPOINTS.updateFavorite.replace(":externalId", externalId)).then((response) => {
            return response.data.data.landsInteractions
        })
    }

    updateArchive(externalId){
        return jwtInterceptor.post(LANDS_INTERACTIONS_ENDPOINTS.updateArchive.replace(":externalId", externalId)).then((response) => {
            return response.data.data.landsInteractions
        })
    }

    async attachTags(tagsIdList, selectedLands) {
        const response = await jwtInterceptor.post(LANDS_INTERACTIONS_ENDPOINTS.attachTags, {tagsIdList: tagsIdList, landsIdList: selectedLands})

        return response.data?.data?.updated === true
    }
}

export const landsInteractionsService = new LandsInteractionsService()