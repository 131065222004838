import React from "react";

import Paper from "@material-ui/core/Paper";
import TablePagination from '@mui/material/TablePagination';

import jwtInterceptor from '../service/jwtInterceptor';
import TableRowUser from '../component/TableRowUser';
import {API_URL} from "../utils/constants";
import {Helmet} from "react-helmet";

class UserContainer extends React.Component {

  DEFAULT_PER_PAGE = 10;
  DEFAULT_PAGE = 0;
  
  constructor(props) {
    super(props);
    this.state = {
      total: 0,
      dataTable: [],
      page: this.DEFAULT_PAGE,
      rowsPerPage: this.DEFAULT_PER_PAGE
    }
  }


  colums = [
    {accessor: "username", show: "Nom"},
    {accessor: "firstname", show: "Prenom"},
    {accessor: "email", show: "E-mail"},
    {accessor: "createdAt", show: "Date inscription"},
    {accessor: "updatedAt", show: "Date dernier login"},
  ]


  handleChangePage = (event, newPage) => {
    this.setState(prevState => {
      return {...prevState, page:newPage}
    });
    this.getData(this.state.rowsPerPage, newPage);
  };

  handleChangeRowsPerPage = (event) => {
    this.setState(prevState => {
      return {...prevState,rowsPerPage: parseInt(event.target.value)}
    });
    this.getData(event.target.value, this.state.page);
  };

  getData = (perPage, page) => {
    this.searchfn({
      size: perPage,
      page: page
    })
  }

    componentDidMount() {
      this.getData(this.DEFAULT_PER_PAGE, this.DEFAULT_PAGE);
    }

    searchfn = (filter) => {
      jwtInterceptor.get(`${API_URL}/user?size=${filter.size}&page=${filter.page}`).then(res => {

        this.setState(prevState => {
          return {
            ...prevState,
            total: res.data.data.total,
            dataTable: res.data.data.results
          }
        });
      })
    }

    

    
    
    render() {
      /****Custom variable styles */
      const fontWeightTitle = {
        fontWeight:"600"
        };
      return (
        <div className='my-4 px-4'>
          <Helmet>
            <title>Gérer les utilisateurs - Mixdata</title>
          </Helmet>
          <Paper className="py-4 px-4 rounded shadow-none App-container w-100 m-0">
            <h3 className='mb-4' style={fontWeightTitle}>Utilisateurs</h3>
            <div className='overflow-auto' style={{ maxHeight: 'calc(100vh - 300px)' }}>
              <table className='table' style={{
                margin: 'auto',
                position: 'relative',
                width: '100%',
                borderCollapse: 'collapse'
              }}>
                <thead style={{
                  background: "#eeeeee",
                  borderBottom: "4px #DEE2E6 solid",
                  fontWeight: "normal",
                  color: "#363636",
                  marginBottom: "5px",
                  position: 'sticky',
                  top: 0,
                  zIndex: 1000
                }}>
                  <tr>
                    {this.colums.map((field) => (
                      <th style={{
                        width: '16.66%',
                        padding: '10px',
                        textAlign: 'left'
                      }} 
                      scope='col' 
                      key={field.accessor}
                      >
                        {field.show}
                      </th>
                    ))}
                    <th style={{
                      width: '16.66%',
                      padding: '10px',
                      textAlign: 'left'
                    }} 
                    scope='col' 
                    key={"actions"}
                    >
                      Actions
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.dataTable.map((row, index) => (
                    <TableRowUser 
                      key={row._source.id} 
                      keyIndex={index} 
                      row={row}
                    />
                  ))}
                </tbody>
              </table>
            </div>
            <TablePagination
              component="div"
              count={this.state.total}
              page={!this.state.page || this.state.page <= 0 ? 0 : this.state.page}
              onPageChange={this.handleChangePage}
              rowsPerPage={this.state.rowsPerPage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
              labelRowsPerPage="Lignes par page"
              backIconButtonProps={{
                'aria-label': 'Précédent',
                className:"PreviousBtn"
              }}
              nextIconButtonProps={{
                'aria-label': 'Suivant',
                className:"NextBtn"
              }}
            />
          </Paper>
        </div>
      );
              }
}

export default UserContainer;