import Chip from "@mui/material/Chip";
import React, {useState} from "react";
import {Close} from "@material-ui/icons";
import {DetachTagDialog} from "./DetachTagDialog";

export const LandsTag = ({tag, onDetach}) => {


    return (
            <Chip
                key={`badge-attached-tag-${tag._id}`}
                sx={{background: `${tag.bgColorHex}`, color: "white"}}
                label={
                    tag.name
                }
                onDelete={() => {
                    onDetach()
                }}
                deleteIcon={<Close style={{ color: "white" }} fontSize={"small"} />}
            />
    )
}