import React, { useState, useEffect } from "react";
import { Box, Autocomplete, Button, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import { ToastContainer } from "react-toastify";
import InputAdornment from "@mui/material/InputAdornment";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import { Instagram } from "@material-ui/icons";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { Language } from "@mui/icons-material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import jwtInterceptor from "../../../service/jwtInterceptor";
import { CONTACT_ENDPOINTS } from "../../../utils/api";
import { z } from "zod";
import { landService } from "../../../service/crm/LandService";
import {useToast} from "../../../Context/ToastContext";
import PropTypes from "prop-types";

const CreateContact = ({ 
  closeIt, 
  contact, 
  numParcelle,
  isEditMode, 
  onContactCreated, 
  onContactUpdated 
}) => {
  const [errors, setErrors] = useState({});
  const [mails, setMails] = useState([{ id: 1, label: `email 1`, value: "" }]);
  const [landsOptions, setLandsOptions] = useState([]);
  

  const [selectedParcelle, setSelectedParcelle] = useState({
    label: "",
    value: null,
  });
  const _id = contact?._id ?? undefined
  const [nom, setNom] = useState(contact?.lastName ?? "");
  const [prenom, setPrenom] = useState(contact?.firstName ?? "");
  const [habitation, setHabitation] = useState(contact?.address ?? "");
  const [numeroFixe, setNumeroFixe] = useState(
    contact?.phones &&
      contact.phones.filter((phone) => phone.type === "work").length > 0
      ? contact.phones.filter((phone) => phone.type === "work")[0].number
      : ""
  );
  const [numeroMobile, setNumeroMobile] = useState(
    contact?.phones &&
      contact.phones.filter((phone) => phone.type === "work").length > 0
      ? contact.phones.filter((phone) => phone.type === "mobile")[0].number
      : ""
  );
  const [siteWeb, setSiteWeb] = useState(
    contact?.website ?? ""
  );
  const [facebook, setFacebook] = useState(
    contact?.facebook ?? ""
  );
  const [linkedin, setLinkedin] = useState(
    contact?.linkedin ?? ""
  );
  const [instagram, setInstagram] = useState(
    contact?.instagram ?? ""
  );
  const [twitter, setTwitter] = useState(
    contact?.twitter ?? ""
  );
  const [whatsapp, setWhatsapp] = useState(
    contact?.whatsapp
      ? contact.whatsapp.replace("https://wa.me/", "")
      : ""
  );
  const [types, setType] = useState(
    contact?.types
      ? { label: contact.types, value: contact.types }
      : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const toaster = useToast()

  //Toasts
  const successtoast = () => toaster.notifySuccess((contact ? "Contact modifié avec succès" : "Contact créé avec succès"));
  const errortoast = () => toaster.notifyError((contact ? "Problème lors de la modification" :  "Problème lors de la création"));

  const style = {
    position: "absolute",
    top: { xl: "50%", lg: "50%", md: "50%", sm: "50%", xs: "50%" },
    paddingInline:{xl: "32px", lg: "32px", md: "32px", sm: "32px", xs: "50px"},
    left: { xl: "50%", lg: "58%", md: "58%", sm: "53%", xs: "58%" },
    "@media (max-width:899px) and (min-width:767px)": {
      // left: "62% !important",
      width: "540px !important",
    },
    transform: "translate(-50%, -50%)",
    maxWidth: {
      xl: 900,
      lg: 900,
      md: 600,
      sm: 600,
      xs: 300,
    },
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    overflowY: "auto",
    height: "741.3px",
    width: "100%",
    zIndex: "999999",
  };

  const getPhoneNumbers = () => {
    return [
      {
        number: numeroFixe,
        type: "work",
      },
      {
        number: numeroMobile,
        type: "mobile",
      },
    ];
  };

  const fetchLands = () => {
    console.log("numParcelle", numParcelle);
    if (numParcelle) {
      setSelectedParcelle({
        label: numParcelle,
        value: numParcelle.toString(),
      });
    } else {
      landService.getMyLand().then((lands) => {
        setLandsOptions(
          lands.map((land) => {
            const cityName = land.resume.city_name ?? ""
            const regionName = land.resume.region_name ? land.resume.region_name : ""

            return {
              label: land.resume.code_number
                ? land.resume.code_number.toString() + ` (${land.resume.area}m² - ${cityName} - ${regionName})`
                : "",
              value: land._id.toString(),
            };
          })
        );
      });
    }
  };

  const fetchData = () => {
    fetchLands();
  };
  const setDefaultMail = () => {
    if (contact?.emails && contact.emails.length > 0) {
      const emails = [];
      contact.emails.map((email, index) => {
        const rowId = index + 1;
        emails.push({
          id: rowId,
          label: `email ${rowId}`,
          value: email,
        });
      });
      setMails(emails);
    }
  };
  const setDefaultValues = () => {
    if (contact?.lands && contact.lands.length > 0) {
      const land = contact.lands[0];
      const cityName = land.resume.city_name ?? "";
      const regionName = land.resume.region_name ? `- ${land.resume.region_name}` : "";
      const area = land.resume.area ? `${land.resume.area}m² - ` : "";
      
      setSelectedParcelle({
        label: land.resume.code_number 
          ? `${land.resume.code_number} (${area}${cityName} ${regionName})`
          : "",
        value: land._id.toString()
      });
    }
    setDefaultMail();
  };


  useEffect(() => {
    if (isEditMode && contact) {
      setDefaultValues();
    } else if (!isEditMode && !numParcelle) {
      fetchLands();
    } else if (numParcelle) {
      setSelectedParcelle({
        label: numParcelle,
        value: numParcelle.toString(),
      });
    }
  }, [contact, isEditMode, numParcelle]);

  useEffect(() => {
    fetchData();
  }, []);

  

  const UrlSchema = z
    .string()
    .regex(/^https:\/\/.+/, { message: "L'URL doit commencer par 'https://'" })
    .optional()
    .or(z.string().length(0));
  const WhatsappUrlSchema = z
    .string()
    .regex(/^\d{7,15}$/, { message: "Numéro de téléphone invalide" })
    .optional()
    .or(z.string().length(0));
  const EmailSchema = z
    .string()
    .regex(/^(?:[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}|)$/)
    .optional();
  let contactSchema;
  contactSchema = z.object({
    lastName: z
        .string()
        .max(50, {message: "50 caractères max"})
        .min(1, {message: "Champ obligatoire"}),
    firstName: z
        .string()
        .max(50, {message: "50 caractères max"})
        .min(1, {message: "Champ obligatoire"}),
    address: z.string().max(250, {message: "250 caractères max"})
        .min(1, {message: "Champ obligatoire"}),
    fix_phone: z
        .string()
        .regex(
            /^$|(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
            {message: "Numéro de téléphone invalide"}
        )
        .optional(),
    mobile_phone: z
        .string()
        .regex(
            /^$|(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?/,
            {message: "Numéro de téléphone invalide"}
        )
        .optional(),
    website: UrlSchema,
    facebook: UrlSchema,
    linkedin: UrlSchema,
    twitter: UrlSchema,
    instagram: UrlSchema,
    whatsapp: WhatsappUrlSchema,
    types: z.string().min(1, {message: "Champ obligatoire"}),
    emails: z.array(z.string()).refine(
        (emails) => {
          return emails.every((email) => EmailSchema.safeParse(email).success);
        },
        {message: "Un ou plusieur email sont invalides"}
    ),
    selectedParcelle: z.object({
      label: z.string().min(1, {message: "Champ obligatoire"}),
      value: z.string().min(1, {message: "Champ obligatoire"}),
    })
  });

  const verifyContactData = async (contactData) => {
    try {
      const fix_phoneObject = contactData.phones.filter(
        (phone) => phone.type === "work"
      )[0];
      const mobile_phoneObject = contactData.phones.filter(
        (phone) => phone.type === "mobile"
      )[0];
      const verifiableContact = {
        ...contactData,
        fix_phone: fix_phoneObject ? fix_phoneObject.number : "",
        mobile_phone: mobile_phoneObject ? mobile_phoneObject.number : "",
      };
      console.log("verifiableContact", verifiableContact);
      contactSchema.parse(verifiableContact);
    } catch (error) {
      if (error instanceof z.ZodError) {
        const validationErrors = {};
        error.errors.forEach((err) => {
          const fieldName = err.path[0];
          validationErrors[fieldName] = err.message;
        });
        setErrors(validationErrors);
        throw error;
      } else {
        throw error;
      }
    }
  };

  const handleAddContact = async () => {
    setIsLoading(true);
    try {
      const contactRequest = buildContactRequest();
      await verifyContactData(contactRequest);

      const contactWithWhatsApp = {
        ...contactRequest,
        whatsapp: contactRequest.whatsapp
          ? `https://wa.me/${contactRequest.whatsapp}`
          : "",
      };

      const newContactResponse = await jwtInterceptor.post(
        CONTACT_ENDPOINTS.new,
        contactWithWhatsApp
      );

      const newContact = newContactResponse.data.data.records.shift();
      onContactCreated(newContact);
      successtoast();
      closeIt();

    } catch (error) {
      console.log('error', error)
      errortoast();
    }
    setIsLoading(false);
  };

  const getValidateButtonLabel = () => {
    return contact ? "Modifier" : "Ajouter";
  };

  const buildContactRequest = () => {
    const phone_numbers = getPhoneNumbers();
    const landsAttribute = [
      {
        _id: selectedParcelle.value,
      },
    ];
    const emailsData = mails.map((email) => email.value);
    const selectedParcelleData = selectedParcelle.value
    ? {
      label: String(selectedParcelle.label),
      value: selectedParcelle.value,
    }
    : undefined;

    return {
      _id: _id,
      lastName: nom,
      firstName: prenom,
      address: habitation,
      phones: phone_numbers,
      website: siteWeb,
      facebook,
      instagram,
      linkedin,
      twitter,
      whatsapp,
      types: types,
      lands: landsAttribute,
      emails: emailsData,
      selectedParcelle: selectedParcelleData,
    };
  };


  const handleAddEmail = () => {
    setMails((prevMails) => [
      ...prevMails,
      {
        id: prevMails.length + 1,
        label: `email ${prevMails.length + 1}`,
        value: "",
      },
    ]);
  };

  const handleOnChangeEmail = (id, value) => {
    const newMails = mails.map((email) => {
      if (email.id === id) {
        return { ...email, value: value };
      } else {
        return email;
      }
    });
    setMails(newMails);
  };


  const type = [
    {
      label: "Propriétaire",
      value: "Propriétaire",
    },
    {
      label: "Prospect",
      value: "Prospect",
    },
  ];
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <Box sx={style}>
        <h4 style={{ marginBottom: 20 }}>{isEditMode ? "Modification de contact" : "Création de contact"}</h4>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": {
              my: 1,
              maxWidth: {
                xl: "400px",
                lg: "400px",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              width: "100%",
            },
            "& .boxfield": {
              maxWidth: {
                xl: "400px",
                lg: "400px",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              width: "100%",
            },
            "& .boxfield .MuiTextField-root": {
              maxWidth: {
                xl: "400px",
                lg: "400px",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              width: "100%",
            },
            "& .parcels .MuiTextField-root": { maxWidth: "100%" },
            "& .socials .inners": {
              maxWidth: {
                xl: "400px",
                lg: "400px",
                md: "100%",
                sm: "100%",
                xs: "100%",
              },
              width: "100%",
            },
          }}
          noValidate
          autoComplete="off"
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box className="boxfield">
              <TextField
                id="nom"
                label="nom *"
                fullWidth
                value={nom}
                onChange={(e) => setNom(e.target.value)}
              />
              {errors.lastName ? (
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.lastName}
                </p>
              ) : (
                <></>
              )}
            </Box>
            <Box className="boxfield">
              <TextField
                required
                id="prenom"
                label="prénom"
                fullWidth
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
              {errors.firstName ? (
                <p
                  style={{ color: "red", textAlign: "left", paddingLeft: "2%" }}
                >
                  {errors.firstName}
                </p>
              ) : (
                <></>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box className="boxfield">
              <TextField
                id="numero_fixe"
                label="fixe"
                fullWidth
                value={numeroFixe}
                onChange={(e) => setNumeroFixe(e.target.value)}
              />
              {errors.fix_phone ? (
                <p style={{ color: "red" }}>{errors.fix_phone}</p>
              ) : (
                <></>
              )}
            </Box>
            <Box className="boxfield">
              <TextField
                id="numero_mobile"
                label="mobile"
                fullWidth
                value={numeroMobile}
                onChange={(e) => setNumeroMobile(e.target.value)}
              />
              {errors.mobile_phone && (
                <p style={{ color: "red" }}>{errors.mobile_phone}</p>
              )}
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            <Box className="boxfield">
              <TextField
                id="habitation"
                label="Adresse *"
                fullWidth
                value={habitation}
                onChange={(e) => setHabitation(e.target.value)}
              />
              {errors.address ? (
                <p style={{ color: "red", textAlign: "left" }}>
                  {errors.address}
                </p>
              ) : (
                <></>
              )}
            </Box>
            <Box className="boxfield">
              <Autocomplete
                disablePortal
                id="type"
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required
                    sx={{ width: "100%" }}
                    label="type de contact"
                  />
                )}
                fullWidth
                options={type}
                onInputChange={(event, newInputValue) => {
                  setType(newInputValue);
                }}
                value={types}
              />
              {errors.types ? (
                <p
                  style={{
                    color: "red",
                    width: "100%",
                    textAlign: "left",
                    paddingLeft: "2%",
                  }}
                >
                  {errors.types}
                </p>
              ) : (
                <></>
              )}
            </Box>
          </Box>

          {!isEditMode && !numParcelle && (
        <Box
          className="parcels"
          sx={{
            justifyContent: "space-between",
            "& .MuiTextField-root": { maxWidth: "100%" },
          }}
        >
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            sx={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Parcelle n° *"
                value={selectedParcelle.label}
                sx={{ maxWidth: "100%" }}
              />
            )}
            fullWidth
            options={landsOptions}
            onChange={(event, newValue) => {
              setSelectedParcelle(newValue ?? { label: "", id: null });
            }}
            isOptionEqualToValue={(option, value) => option.value === value}
          />
          {errors.selectedParcelle && (
            <p style={{ color: "red", width: "50%", textAlign: "left" }}>
              Champ obligatoire
            </p>
          )}
        </Box>
      )}

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {mails.map((email, index) => (
              <Box
                key={email.id}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: index === mails.length - 1 ?  {xl:"calc(50% - 10px)" , lg:"calc(50% - 10px)" , md: "100%", sm: "100%", xs: "100%"} : "100%",
                  maxWidth: index === mails.length - 1 ? "none" : {xl:"400px" , lg:"400px" , md: "100%", sm: "100%", xs: "100%"},
                }}
              >
                <TextField
                  id={`${email.id}`}
                  label={email.label}
                  value={email.value}
                  onChange={(e) =>
                    handleOnChangeEmail(email.id, e.target.value)
                  }
                  fullWidth
                  sx={{ mr: index === mails.length - 1 ? 2 : "0" }}
                />
                {index === mails.length - 1 && (
                  <AddCircleIcon
                    sx={{ color: "#299CDB", cursor: "pointer" }}
                    onClick={handleAddEmail}
                  />
                )}
              </Box>
            ))}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            {errors.emails && (
              <p style={{ color: "red", width: "50%", textAlign: "left" }}>
                {errors.emails}
              </p>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
            }}
            className="socials"
          >
            <Box className="inners">
              <TextField
                label="facebook"
                id="facebook"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <FacebookOutlinedIcon sx={{ color: "#1196f5" }} />
                    </InputAdornment>
                  ),
                }}
                value={facebook}
                onChange={(e) => setFacebook(e.target.value)}
              />
              {errors.facebook && (
                <p style={{ color: "red" }}>{errors.facebook}</p>
              )}
            </Box>

            <Box className="inners">
              <TextField
                label="linkedin"
                id="linkedin"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <LinkedInIcon sx={{ color: "#0a66c2" }} />
                    </InputAdornment>
                  ),
                }}
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />
              {errors.linkedin && (
                <p style={{ color: "red" }}>{errors.linkedin}</p>
              )}
            </Box>
            <Box className="inners">
              <TextField
                label="twitter"
                id="twitter"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <TwitterIcon sx={{ color: "#1d9bf0" }} />
                    </InputAdornment>
                  ),
                }}
                value={twitter}
                onChange={(e) => setTwitter(e.target.value)}
              />
              {errors.twitter && (
                <p style={{ color: "red" }}>{errors.twitter}</p>
              )}
            </Box>
            <Box className="inners">
              <TextField
                label="whatsapp"
                id="whatsapp"
                sx={{ width: "100%" }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      https://wa.me/
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <WhatsAppIcon sx={{ color: "#25d366" }} />
                    </InputAdornment>
                  ),
                }}
                value={whatsapp}
                onChange={(e) => setWhatsapp(e.target.value)}
              />
              {errors.whatsapp && (
                <p style={{ color: "red" }}>{errors.whatsapp}</p>
              )}
            </Box>
            <Box className="inners">
              <TextField
                label="instagram"
                id="instagram"
                sx={{ width: "100%" }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Instagram style={{ color: "#833AB4" }} />
                    </InputAdornment>
                  ),
                }}
                value={instagram}
                onChange={(e) => setInstagram(e.target.value)}
              />
              {errors.instagram && (
                <p style={{ color: "red" }}>{errors.instagram}</p>
              )}
            </Box>
            <Box className="inners">

            <TextField
                id="site_web"
                label="site web"
                fullWidth
                sx={{ width: "100%" }}
                value={siteWeb}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Language sx={{ color: "#0A66C2" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSiteWeb(e.target.value)}
              />
              {errors.website && (
                <p style={{ color: "red" }}>{errors.website}</p>
              )}
            </Box>
          </Box>
          
          <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>
            <Button
              sx={{
                background: "#757575",
                color: "#fff",
                "&:hover": { background: "#757575" },
                mr: 2,
              }}
              onClick={closeIt}
            >
              Annuler
            </Button>
            {isLoading ? (
              <CircularProgress color="secondary" />
            ) : (
              <Button
                sx={{
                  background: "#299CDB",
                  color: "#fff",
                  "&:hover": { background: "#299CDB" },
                }}
                onClick={handleAddContact}
              >
                {getValidateButtonLabel()}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

CreateContact.propTypes = {
  closeIt: PropTypes.func.isRequired,
  contact: PropTypes.object,
  numParcelle: PropTypes.string,
  isEditMode: PropTypes.bool,
  onContactCreated: PropTypes.func,
  onContactUpdated: PropTypes.func
};

export default CreateContact;
