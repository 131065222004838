import React, { useState } from "react";
import Paper from "@material-ui/core/Paper";
import TablePagination from "@mui/material/TablePagination";
import Table from "react-bootstrap/Table";

import TableRowLand from "../component/TableRowLand";
import jwtInterceptor from "../service/jwtInterceptor";
import { API_URL } from "@utils/constants";
import { useToast } from "../Context/ToastContext";
import CustomBtn from "../component/customBtns/CustomBtn";
import { Box } from "@material-ui/core";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { LandsFilterCard } from "../component/LandsFilterCard";
import { Helmet } from "react-helmet";

import ParcelResume from "../component/parcelResume/ParcelResume";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { Explore } from "@mui/icons-material";




const LandContainer = (props) => {
  const DEFAULT_PER_PAGE = 10;
  const DEFAULT_PAGE = 0;

  const toaster = useToast();

  const [selectedLandId, setSelectedLandId] = useState(null);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_PER_PAGE);
  const [loading, setLoading] = useState(true);
  const [showResume, setShowResume] = useState(false);

  const [filters, setFilters] = useState({
    parcelNumber: "",
    commune: "",
    address: "",
    zone: "",
    owner: "",
    surface: "",
    constructionDate: "",
  });

  const colums = [
    { accessor: "code_number", show: "N° Parcelle" },
    { accessor: "area", show: "Surface parcelle" },
    { accessor: "address_full", show: "Adresse" },
    { accessor: "city_name", show: "Commune" },
    { accessor: "region_name", show: "Canton" },
    { accessor: "principal_type", show: "Zone d'affectation" },
    { accessor: "building_nb", show: "Propriétaire(s)" },
  ];

  const handleChangePage = (event, newPage) => {
    setLoading(true);
    setPage(newPage);
    getData(rowsPerPage, newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value));
    getData(event.target.value, page);
  };

  const searchfn = async (filter) => {
    setLoading(true);
    setFilters(filter);
    if (filter.page !== undefined) setPage(filter.page);
    if (filter.size) setRowsPerPage(filter.size);
    console.log("page ==>", page);
    return await jwtInterceptor
      .post(`${API_URL}/lands/search`, filter)
      .then((res) => {
        setTotal(res.data.data.total);
        setDataTable(res.data.data.results);

      })
      .catch((error) => {
        toaster.notifyError(error.message);
      })
      .finally(() => {
        setLoading(false);
      })
  };

  const getData = (perPage, page) => {
    searchfn({
      ...filters,
      page,
      size: perPage,
    });
  };

  const toggleResume = () => {
    setShowResume(!showResume);
  };

  const handleOnClickRow = (landId) => {
    setSelectedLandId(landId);
    toggleResume();
  };

  const fontWeightTitle = {
    fontWeight: "600",
  };

  const onRefreshData = () => {
    searchfn(filters);
  };

  const renderLands = () => {
    if(dataTable.length > 0) {
      return (
          <>
            {dataTable.map((row, index) => (
                <TableRowLand
                    clickOne={(event) => handleOnClickRow(row._source.id)}
                    key={row._source.id}
                    keyIndex={index}
                    row={row}
                    onChange={onRefreshData}
                />
            ))}
          </>

      )
    }
    else {
      return (
          <tr>
            <td colSpan={colums.length} style={{ width: "1614px" }}>
              Aucun résultat trouvé
            </td>
          </tr>
      )
    }
  }

  return (
    <Box className="my-4 w-100  px-4">
      <Helmet>
        <title>Rechercher des parcelles - Mixdata</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid
          className="filters shadow-none"
          xl={3}
          lg={3}
          md={4}
          sm={12}
          xs={12}
        >
          <Paper className="shadow-none" >
            <LandsFilterCard handleSearch={(filters) => searchfn(filters)} />
          </Paper>
        </Grid>
        <Grid xl={9} lg={9} md={8} sm={12} xs={12}>
          <Box className="mb-3 d-flex justify-content-between">
            <h3 style={{ ...fontWeightTitle }}>Toutes les parcelles</h3>
            <Nav.Link as={Link} to="/map">
              <CustomBtn
                label="Map"
                bgColor="#299CDB"
                iconBg={"#3ba9e5"}
                icon={<Explore />}
              />
            </Nav.Link>
          </Box>
          <Box className="listparcels h-auto w-100 m-0 position-relative z-index">
            <Paper
              className={
                "py-4 px-4 rounded shadow-none App-container m-0 overflow-hidden position-relative z-index" +
                " " +
                " d-block"
              }
            >
              <Box className="overflow-auto">
                <Table>
                  <thead
                    style={{
                      background: "#eeeeee",
                      borderBottom: "2px #DEE2E6 solid",
                      fontWeight: "normal",
                      color: "#363636",
                      marginBottom: "5px",
                    }}
                  >
                    <tr class="parcelles-table">
                      {colums.map((field) => {
                        return (
                          <th align="left" key={field.accessor}>
                            {field.show}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody className="parcelTables">
                    {loading ? (
                      <tr>
                        <td
                          colSpan={colums.length + 2}
                          style={{ width: "1614px" }}
                        >
                          Chargement en cours...
                        </td>
                      </tr>
                    ) : renderLands()}
                  </tbody>
                </Table>
              </Box>
              <TablePagination
                component="Box"
                count={total}
                page={!page || page <= 0 ? 0 : page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                labelRowsPerPage="Lignes par page"
                onRowsPerPageChange={handleChangeRowsPerPage}
                backIconButtonProps={{
                  "aria-label": "Précédent",
                  className: "PreviousBtn",
                }}
                nextIconButtonProps={{
                  "aria-label": "Suivant",
                  className: "NextBtn",
                }}
              />
            </Paper>
          </Box>
        </Grid>
      </Grid>
      <Box className={`resume ${showResume ? "open" : "closed"}`}>
        <ParcelResume closeFenetre={toggleResume} landId={selectedLandId} >

        </ParcelResume>
      </Box>
    </Box>
  );
};

export default LandContainer;
