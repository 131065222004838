import moment from "moment";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {useEffect} from "react";
import Form from "react-bootstrap/Form";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

export const TagTablesRow = ({tag, onDeleteTag, onUpdate}) => {
    const [keyIndex, setKeyIndex] = React.useState(0);

    useEffect(() => {
        setKeyIndex(`key-tr-row-tag-${tag._id}`)
    }, [tag]);

    const tdStyle = {
        padding: '10px',
        textAlign: 'left',
        width: '25%',
        borderBottom: '1px solid #dee2e6'
    };

    return (
        <tr style={{
            backgroundColor: keyIndex % 2 === 0 ? '#fff' : '#f8f9fa',
            width: '100%'
        }}>
            <td style={tdStyle}>
                {tag.name}
            </td>
            <td style={tdStyle}>
                <Form.Control
                    className="input color-input"
                    type="color"
                    placeholder="couleur du tag"
                    required
                    value={tag.bgColorHex}
                    disabled
                    style={{maxWidth: "120px", width: "100%"}}
                />
            </td>
            <td style={tdStyle}>
                {moment(tag.createdAt).format('DD-MM-YYYY')}
            </td>
            <td style={tdStyle}>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    color={"info"}
                    onClick={onUpdate}
                >
                    <ModeEditIcon/>
                </IconButton>
                <IconButton
                    aria-label="expand row"
                    size="small"
                    color={"error"}
                    onClick={onDeleteTag}
                >
                    <DeleteIcon/>
                </IconButton>
            </td>
        </tr>
    )
}