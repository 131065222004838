import StarIcon from '@mui/icons-material/Star';
import StarBorderOutlinedIcon from '@mui/icons-material/StarBorderOutlined';
import React, { useState } from "react";
import {TABLE_ROW_ACTIONS} from "../utils/constants";
import {ChangeFavoriteDialog} from "./FavoriteBox";
import {ChangeArchiveActionBox} from "./ChangeArchiveActionBox";
import UpdateIcon from "@mui/icons-material/Update";
import {Close, UpdateSharp} from "@material-ui/icons";
import {Box, Checkbox} from "@mui/material";
import Chip from "@mui/material/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import {LandsTag} from "./tags/LandsTag";
import {AttachedLandsTagList} from "./parcelResume/AttachedLandsTagList";

function TableRowLand(props) {
  const { row, keyIndex, actions, clickOne = [] , onChange, onChangeCheck, showTags = false} = props;
  const [openFavoriteDialog, setOpenFavoriteDialog] = useState(false)
  const [openArchiveDialog, setOpenArchiveDialog] = useState(false)

  const handleClickOpenAddFavorite = (event) => {
      event.stopPropagation()
      setOpenFavoriteDialog(true)
  }

    const handleClickOpenChangeArchive = (event) => {
        event.stopPropagation()
        setOpenArchiveDialog(true)
    }

    const onCloseFavoriteDialog = (event) => {
        event.stopPropagation()
        setOpenFavoriteDialog(false)
        onChange()
    }

    const onCloseArchiveDialog = (event) => {
        event.stopPropagation()
        onChange()
        setOpenArchiveDialog(false)
    }

    const handleRowClick = () => {
      clickOne();
    }

    const truncate = (str) => {
      return str.length > 20 ? str.substring(0, 35) + "..." : str;
    };


    const clickCheckLand = (event) => {
        event.stopPropagation()
    }

    const onChangeCheckBox = (e) => {
        e.stopPropagation()
        onChangeCheck(e.target.checked)
    }

    return (
    <tr sx={{ '& > *': { borderBottom: 'none' } , '&:hover': {backgroundColor: 'lightblue'} }} key={keyIndex} style={{cursor: 'pointer'}} onClick={handleRowClick}>

        {actions && actions.length > 0 ?
        <td align='left' >
          <div className={'d-flex  justify-content-center align-items-start'}>
              {(actions.includes(TABLE_ROW_ACTIONS.multipleSelection)) &&
                <Checkbox
                    className={"pt-0"}
                    id={`checkbox-${keyIndex}`}
                    onChange={(e) => onChangeCheckBox(e)}
                    onClick={(e) => e.stopPropagation()}
                >

                </Checkbox>
              }

              {(actions.includes(TABLE_ROW_ACTIONS.favorite)) ?
                <div className={'mx-2'} onClick={handleClickOpenAddFavorite} >
                    {(row._source.landsInteractions?.isFavorites) ? <StarIcon style={{ color: 'brown' }} /> : <StarBorderOutlinedIcon style={{ color: 'black' }} />}
                    <ChangeFavoriteDialog row={row._source} open={openFavoriteDialog} onClose={onCloseFavoriteDialog}/>
                </div> : ""
              }
              {(actions.includes(TABLE_ROW_ACTIONS.archive)) ?
                  <div className={'mx-2'} onClick={handleClickOpenChangeArchive} >
                      {(row._source.landsInteractions?.isArchived) ? <UpdateIcon style={{ color: 'brown' }} /> : <UpdateSharp style={{ color: 'black' }} />}
                      <ChangeArchiveActionBox row={row._source} open={openArchiveDialog} onClose={onCloseArchiveDialog}/>
                  </div> : ""
              }
          </div>
        </td>
        : ""
        }
        <td>{row._source.code_number}</td>
        <td align="left">{row._source.area} m<sup>2</sup></td>
        <td>
          {row._source.address_street} {row._source.address_street_nb}
        </td>
        <td>
        {row._source.city_name} 
        </td>
        <td>{row._source.region_name}</td>
        <td align="left">{row._source.main_lupa_name}</td>
        <td align="left">{truncate(row._source.owner_list)}</td>
        {showTags &&
            <td align="left" style={{width: "250px"}} onClick={e => e.stopPropagation()}>
                <Box className={"w-100 d-flex flex-row flex-wrap gap-1"}>
                   <AttachedLandsTagList land={row._source} />
                </Box>
            </td>
        }
      </tr>
  );
}

export default TableRowLand;
