import { Box, Card, Button, IconButton, Collapse } from "@mui/material";
import Form from "react-bootstrap/Form";
import React, { useEffect, useState, useRef } from "react";
import TextField from "@mui/material/TextField";
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export const LandsMapFilterCard = ({ handleSearch, defaultFilters, isMapReady }) => {
  const [landsCodeNumber, setLandsCodeNumber] = useState(defaultFilters?.landsCodeNumber ?? "");
  const [zoneAffectation, setZoneAffectation] = useState("");
  const [commune, setCommune] = useState(defaultFilters?.commune ?? "");
  const [address, setAddress] = useState(defaultFilters?.address ?? "");
  const [owner, setOwner] = useState("");
  const [canton, setCanton] = useState(defaultFilters?.canton ?? "");
  const [areaMin, setAreaMin] = useState("");
  const [areaMax, setAreaMax] = useState("");
  const [minYear, setMinYear] = useState("");
  const [maxYear, setMaxYear] = useState("");
  const [minBuildingNbr, setMinBuildingNbr] = useState("");
  const [maxBuildingNbr, setMaxBuildingNbr] = useState("");
  const [showAllFields, setShowAllFields] = useState(false);

  // Référence pour suivre si les filtres ont déjà été appliqués
  const initialFilterApplied = useRef(false);
  // Référence pour stocker le bouton de filtrage
  const filterButtonRef = useRef(null);

  // Mettre à jour les champs lorsque defaultFilters change
  useEffect(() => {
    if (defaultFilters) {
      setLandsCodeNumber(defaultFilters.landsCodeNumber || "");
      setCommune(defaultFilters.commune || "");
      setCanton(defaultFilters.canton || "");
      setAddress(defaultFilters.address || "");
    }
  }, [defaultFilters]);

  // Appliquer les filtres par défaut lorsque la carte est prête
  // et que les filtres ne sont pas vides
  useEffect(() => {
    // Ne rien faire si on a déjà appliqué les filtres ou si la carte n'est pas prête
    if (!isMapReady || initialFilterApplied.current) {
      return;
    }

    // Vérifier si les filtres ne sont pas vides
    if (defaultFilters && !defaultFilters.isEmpty) {
      console.log("Auto-applying filters:", defaultFilters);

      // Utiliser setTimeout pour s'assurer que ce code s'exécute après le rendu
      setTimeout(() => {
        // Option 1: Simuler un clic sur le bouton de filtrage
        if (filterButtonRef.current) {
          filterButtonRef.current.click();
        } else {
          // Option 2: Appeler directement handleSearch si le bouton n'est pas disponible
          const filters = {
            landsCodeNumber: defaultFilters.landsCodeNumber || "",
            commune: defaultFilters.commune || "",
            canton: defaultFilters.canton || "",
            address: defaultFilters.address || "",
            isEmpty: false
          };
          handleSearch(filters);
        }

        // Marquer que les filtres ont été appliqués
        initialFilterApplied.current = true;
      }, 300);
    }
  }, [isMapReady, defaultFilters, handleSearch]);

  const onClickValidate = () => {
    const filters = {
      landsCodeNumber,
      commune,
      canton,
      address,
      zoneAffectation,
      owner,
      areaMin,
      areaMax,
      minYear,
      maxYear,
      minBuildingNbr,
      maxBuildingNbr,
      isEmpty: !(landsCodeNumber || commune || canton || address || zoneAffectation || owner ||
          areaMin || areaMax || minYear || maxYear || minBuildingNbr || maxBuildingNbr)
    };
    console.log("Applying filters manually:", filters);
    handleSearch(filters);
  };

  const onClickClean = () => {
    setCommune("");
    setAreaMin("");
    setAreaMax("");
    setMaxYear("");
    setMinYear("");
    setOwner("");
    setCanton("");
    setAddress("");
    setZoneAffectation("");
    setLandsCodeNumber("");
    setMinBuildingNbr("");
    setMaxBuildingNbr("");

    handleSearch({ isEmpty: true });
  };

  return (
      <>
        {/* vertical */}
        <Card
            className="shadow-none"
            style={{ padding: "20px", backgroundColor: "#fff", height: "100%", zIndex:"99999999" }}
        >
          <Box component="form">
            <Form.Group className="my-3" controlId="1" key="landsCodeNumber">
              <TextField
                  type="text"
                  controlId="1"
                  label="N° Parcelle"
                  placeholder="N° Parcelle"
                  fullWidth
                  value={landsCodeNumber}
                  onChange={(e) => setLandsCodeNumber(e.target.value)}
                  size="small"
              />
            </Form.Group>
            <Form.Group className="my-3" controlId="5" key="Commune">
              <TextField
                  type="text"
                  controlId="5"
                  label="Commune"
                  placeholder="Commune"
                  fullWidth
                  value={commune}
                  onChange={(e) => setCommune(e.target.value)}
                  size="small"
              />
            </Form.Group>
            <IconButton
                onClick={() => setShowAllFields(!showAllFields)}
                sx={{"&:hover" : {background: "none" }, p: 0}}
            >
              {showAllFields ? <><span style={{fontSize: "14px"}}>Afficher moins</span> <ExpandLessIcon /> </> : <><span style={{fontSize: "14px"}}>Filtre avancé</span><ExpandMoreIcon /></>}
            </IconButton>
            <Collapse in={showAllFields} timeout="auto" unmountOnExit>
              <Form.Group className="my-3" controlId="51" key="Canton">
                <TextField
                    type="text"
                    controlId="5"
                    label="Canton"
                    placeholder="Canton"
                    fullWidth
                    value={canton}
                    onChange={(e) => setCanton(e.target.value)}
                    size="small"
                />
              </Form.Group>
              <Form.Group className="my-3" controlId="3" key="address">
                <TextField
                    type="text"
                    controlId="3"
                    label="Adresse"
                    placeholder="Adresse"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    size="small"
                />
              </Form.Group>
              <Form.Group className="my-3" controlId="52" key="zoneAffectation">
                <TextField
                    type="text"
                    controlId="5"
                    label="Zone d'affectation"
                    placeholder="Zone d'affectation"
                    fullWidth
                    value={zoneAffectation}
                    onChange={(e) => setZoneAffectation(e.target.value)}
                    size="small"
                />
              </Form.Group>
              <Form.Group className="mt-3 mb-2 " controlId="6" key="owner">
                <TextField
                    type="text"
                    controlId="6"
                    label="Propriétaire"
                    placeholder="Propriétaire"
                    fullWidth
                    value={owner}
                    onChange={(e) => setOwner(e.target.value)}
                    size="small"
                />
              </Form.Group>

              <Form.Group className="my-1" controlId="61" key="building_nbr">
                <Form.Label>Nombre de batiment</Form.Label>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <TextField
                      type="number"
                      controlId="6"
                      label="min"
                      placeholder="min"
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                      value={minBuildingNbr}
                      onChange={(e) => setMinBuildingNbr(e.target.value)}
                      sx={{ mb: 1, width: "47%" }}
                      size="small"
                  />
                  <TextField
                      type="number"
                      controlId="6"
                      label="max"
                      placeholder="max"
                      InputProps={{ inputProps: { min: 2 } }}
                      fullWidth
                      value={maxBuildingNbr}
                      onChange={(e) => setMaxBuildingNbr(e.target.value)}
                      sx={{width: "47%"}}
                      size="small"
                  />
                </Box>
              </Form.Group>
              <Form.Group className="my-1" controlId="6" key="building_year">
                <Form.Label>Date de construction</Form.Label>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <TextField
                      type="number"
                      controlId="6"
                      label="min"
                      placeholder="min"
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                      value={minYear}
                      onChange={(e) => setMinYear(e.target.value)}
                      sx={{ mb: 1, width: "47%" }}
                      size="small"
                  />
                  <TextField
                      type="number"
                      controlId="6"
                      label="max"
                      placeholder="max"
                      InputProps={{ inputProps: { min: 2 } }}
                      fullWidth
                      value={maxYear}
                      onChange={(e) => setMaxYear(e.target.value)}
                      sx={{width: "47%"}}
                      size="small"
                  />
                </Box>
              </Form.Group>
              <Form.Group  controlId="6" key="area">
                <Form.Label>Surface parcelle</Form.Label>
                <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <TextField
                      type="number"
                      controlId="6"
                      label="min"
                      placeholder="min"
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                      value={areaMin}
                      onChange={(e) => setAreaMin(e.target.value)}
                      sx={{ mb: 1, width: "47%" }}
                      size="small"
                  />
                  <TextField
                      type="number"
                      controlId="6"
                      label="max"
                      placeholder="max"
                      InputProps={{ inputProps: { min: 2 } }}
                      fullWidth
                      value={areaMax}
                      onChange={(e) => setAreaMax(e.target.value)}
                      sx={{width: "47%"}}
                      size="small"
                  />
                </Box>
              </Form.Group>
            </Collapse>

            <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>

              <Button
                  sx={{
                    background: "#757575",
                    color: "#fff",
                    "&:hover": { background: "#757575" },
                    display:"flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    verticalAlign: "center",
                    fontWeight: 500
                  }}
                  onClick={() => onClickClean()}
              >
                <RefreshIcon sx={{mr: 1, fontSize: 18}} />
                REINITIALISER
              </Button>
              <Button
                  ref={filterButtonRef}
                  sx={{
                    background: "#299CDB",
                    color: "#fff",
                    "&:hover": { background: "#299CDB" },
                    ml: 1,
                  }}
                  onClick={() => onClickValidate()}
              >
                Filtrer
              </Button>
            </Box>
          </Box>
        </Card>
      </>
  );
};