import React, {useEffect, useState} from "react";
import {TagTablesRow} from "./TagTablesRow";
import {tagService} from "../../service/crm/TagService";
import {DeletetagDialog} from "./DeletetagDialog";
import {CreateTagModal} from "./CreateTagModal";
import TablePagination from "@mui/material/TablePagination";

export const TagTable = ({needFetch}) => {
    const [tagList, settagList] = React.useState([]);
    const [selectedTagId, setSelectedTagId] = React.useState(null);
    const [isDeletedModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedtag, setSelectedTag] = useState()
    const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
    const [total, setTotal] = useState(0)
    const [pageSize, setPageSize]= useState(10)
    const [pageOffset, setPageOffset]= useState(0)

    const colums = [
        {accessor: "name", show: "Nom"},
        {accessor: "bgColorHex", show: "Couleur de fond"},
        {accessor: "createdAt", show: "Date de création"},
    ]

    useEffect(() => {
        fetchTags({limit: pageSize,  offset: pageOffset})
    }, [needFetch]);

    const fetchTags = (pagination) => {
        tagService.findAllByConnectedUser(pagination).then(response => {
            settagList(response.data.records)
            setTotal(response.data.total_count)
        })
    }

    const onDeleteTag = (tagId) => {
        setSelectedTagId(tagId)
        setIsDeleteModalOpen(true)
    }

    const onTagIsDeleted = (isDeleted) => {
        if(isDeleted)
            settagList(tagList.filter(t => t._id !== selectedTagId))

        setIsDeleteModalOpen(false)
    }

    const onUpdatetag = (tag) => {
        setSelectedTag(tag)
        setIsUpdateModalOpen(true)
    }

    const onTagIsUpdated = (updatedTag) => {
        settagList(tagList.map(tag => {
            if(tag._id === updatedTag._id)
                tag = {...updatedTag}

            return tag
        }))
        setIsUpdateModalOpen(false)
    }

    const handleChangePage = (e, newPage) =>{
        setPageOffset(newPage)
        fetchTags({limit: pageSize,  offset: newPage})
    }

    const handleChangeRowsPerPage = (e) => {
        const newLimit = parseInt(e.target.value, 10);
        setPageSize(newLimit)
        setPageOffset(0)
        fetchTags({limit: newLimit,  offset: 0})
    }

    return (
        <div style={{width: "70%", margin: "auto"}}>
            <div style={{ maxHeight: 'calc(100vh - 300px)', overflow: 'auto' }}>
                <table className='table' style={{
                    margin: 'auto',
                    position: 'relative',
                    width: '100%',
                    borderCollapse: 'collapse'
                }}>
                    <thead style={{
                        background: "#eeeeee",
                        borderBottom: "4px solid rgb(222, 226, 230)",
                        fontWeight: "normal",
                        color: "#363636",
                        marginBottom: "5px",
                        position: 'sticky',
                        top: 0,
                        zIndex: 1000
                    }}>
                        <tr>
                            <th style={{
                                width: '25%',
                                padding: '10px',
                                textAlign: 'left'
                            }}>
                                Nom
                            </th>
                            <th style={{
                                width: '25%',
                                padding: '10px',
                                textAlign: 'left'
                            }}>
                                Couleur de fond
                            </th>
                            <th style={{
                                width: '25%',
                                padding: '10px',
                                textAlign: 'left'
                            }}>
                                Date de création
                            </th>
                            <th style={{
                                width: '25%',
                                padding: '10px',
                                textAlign: 'left'
                            }}>
                                Actions
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {tagList.map((row) => (
                            <TagTablesRow
                                key={row._id}
                                tag={row}
                                onDeleteTag={() => onDeleteTag(row._id)}
                                onUpdate={() => onUpdatetag(row)}
                                columnWidths={{
                                    name: '25%',
                                    bgColorHex: '25%',
                                    createdAt: '25%',
                                    actions: '25%'
                                }}
                            />
                        ))}
                    </tbody>
                </table>
            </div>
            <DeletetagDialog tagId={selectedTagId} isModalOpen={isDeletedModalOpen} onClose={(e) => onTagIsDeleted(e)}/>
            <CreateTagModal open={isUpdateModalOpen} tag={selectedtag} onTagSaved={(e) => onTagIsUpdated(e)} onClose={() => setIsUpdateModalOpen(false)}/>
            <TablePagination
                component="div"
                count={total}
                page={!pageOffset || pageOffset <= 0 ? 0 : pageOffset}
                onPageChange={handleChangePage}
                rowsPerPage={pageSize}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelRowsPerPage="Lignes par page"
                backIconButtonProps={{
                    'aria-label': 'Précédent',
                    className:"PreviousBtn"
                }}
                nextIconButtonProps={{
                    'aria-label': 'Suivant',
                    className:"NextBtn"
                }}
            />
        </div>
    )
}