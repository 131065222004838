import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {landsInteractionsService} from "../service/crm/LandsInteractionsService";
import {useToast} from "../Context/ToastContext";
import { useFavorites } from "../Context/FavoriteContext";

export const ChangeFavoriteDialog = ({row, open, onClose}) => {
    const deleteFavoriteMessage = "Souhaitez-vous supprimer cette parcelle dans vos favoris ?";
    const addFavoriteMessage = "Voulez-vous ajouter cette parcelle dans vos favoris ?";
    const [loadingConfirmModal, setLoadingConfirmModal] = useState(false)
    const [openModal, setOpenModal] = useState(open)
    const toaster = useToast()
    const { getFavoriteStatus, updateFavorite } = useFavorites();

    useEffect(() => {
        setOpenModal(open)
    }, [open]);

    const handleCloseAddFavorite = (event) => {
        event.stopPropagation()
        onClose(event)
    };

    const confirmUpdateFavorite = (event) => {
        setLoadingConfirmModal(true);
        const isCurrentlyFavorite = row.landsInteractions?.isFavorites === true;
    
        landsInteractionsService.updateFavorite(row.id)
          .then((landsInteractions) => {
            if (landsInteractions) {
              // Mettre à jour le contexte
              updateFavorite(row.id, !isCurrentlyFavorite);
              
              if (isCurrentlyFavorite) {
                toaster.notifySuccess("La parcelle a été retirée de vos favoris avec succès !");
              } else {
                toaster.notifySuccess("La parcelle a été ajoutée à vos favoris avec succès !");
              }
            } else {
              toaster.notifyError("Problème durant la récupération de la réponse");
            }
          })
          .catch(error => toaster.notifyError("Erreur WS : " + error.message))
          .finally(() => {
            setLoadingConfirmModal(false);
            handleCloseAddFavorite(event);
          });
      };
    

      const getUpdateFavorisMessage = () => {
        return getFavoriteStatus(row.id) ? deleteFavoriteMessage : addFavoriteMessage;
      };

    return (
        <>
            <Dialog
                open={openModal}
                onClose={handleCloseAddFavorite}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {(row.landsInteractions?.isFavorites) ? "Supprimer favori" : "Ajouter favori"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {getUpdateFavorisMessage()}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseAddFavorite}>Annuler</Button>
                    {loadingConfirmModal ? (
                        <CircularProgress color="secondary" />
                    ) : (
                        <Button onClick={confirmUpdateFavorite} autoFocus>Confirmer</Button>
                    )}
                </DialogActions>
            </Dialog>
        </>
    )
}
