

export function WGS84toLV95(lat, lon) {
  const phi = (lat * 3600 - 169028.66) / 10000;
  const lambda = (lon * 3600 - 26782.5) / 10000;

  const x = 2600072.37 
    + 211455.93 * lambda 
    - 10938.51 * lambda * phi
    - 0.36 * lambda * Math.pow(phi, 2)
    - 44.54 * Math.pow(lambda, 3);

  const y = 1200147.07 
    + 308807.95 * phi 
    + 3745.25 * Math.pow(lambda, 2)
    + 76.63 * Math.pow(phi, 2)
    - 194.56 * Math.pow(lambda, 2) * phi;

  return { x: Math.round(x * 1000) / 1000, y: Math.round(y * 1000) / 1000 };
}