import React, {useState, useEffect, forwardRef, useImperativeHandle} from 'react';
import CloseIcon from "@mui/icons-material/Close";
import {Box, Button} from "@mui/material";
import LayersIcon from '@mui/icons-material/Layers';
import MapTagImage from '../../assets/Images/map/map-presets.png'
import {tagService} from "../../service/crm/TagService";
import {landsInteractionsService} from "../../service/crm/LandsInteractionsService";

const MapData = forwardRef(({ onTagFilter }, ref) => {
    const [isOpen, setIsOpen] = useState(false);
    const [allTags, setAllTags] = useState([]);
    const [tagsWithParcels, setTagsWithParcels] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [isTagDisplayed, setIsTagDisplayed] = useState(false);
    const [isRendered, setIsRendered] = useState(false);

    useImperativeHandle(ref, () => ({
        toggleWindow,
        closeWindow
    }));

    useEffect(() => {
        fetchAllTags()
    }, [])

    const fetchAllTags = () => {
        tagService.findAllByConnectedUser().then(response => {
            console.log('response', response)
            setAllTags(response.data.records)

            landsInteractionsService.getLands().then(landsResponse => {
                if (landsResponse && landsResponse.data && landsResponse.data.records) {
                    const tagsWithParcelsSet = new Set();
                    landsResponse.data.records.forEach(land => {
                        if (land.tags && Array.isArray(land.tags)) {
                            land.tags.forEach(tag => {
                                if (tag && tag._id) {
                                    tagsWithParcelsSet.add(tag._id);
                                }
                            });
                        }
                    });
                    const filteredTags = response.data.records.filter(tag => 
                        tag && tag._id && tagsWithParcelsSet.has(tag._id)
                    );
                    setTagsWithParcels(filteredTags);
                } else {
                    console.error('Invalid response format from getLands');
                    setTagsWithParcels([]);
                }
            }).catch(error => {
                console.error('Error fetching lands:', error);
                setTagsWithParcels([]);
            });
        }).catch(error => {
            console.error('Error fetching tags:', error);
            setAllTags([]);
            setTagsWithParcels([]);
        });
    }

    const tagsIsVisible = () => {
        setIsTagDisplayed(!isTagDisplayed)
    }

    const closeWindow = () => {
        setIsOpen(false);
        setTimeout(() => {
            setIsRendered(false);
        }, 500);
    };

    const toggleWindow = () => {
        if (isOpen) {
            setIsOpen(false);
            setTimeout(() => {
                setIsRendered(false);
            }, 500);
        } else {
            // Ouverture
            setIsRendered(true);
            setTimeout(() => {
                setIsOpen(true);
            }, 10);
        }
    };

    const handleTagClick = (tag) => {
         onTagFilter(tag);
         setSelectedTags([...selectedTags, tag]);
    }

    return (
        <>
            <button
                style={{
                    position: 'fixed',
                    top: '20px',
                    right: '150px',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    border: 'none',
                    padding: '5px',
                    borderRadius:'3px'
                }}
                onClick={toggleWindow}
            >
                <LayersIcon className='me-2' />
                Carte & données
            </button>

            {isRendered && (
                <div
                    className={`slide-panel ${isOpen ? 'slide-panel-open' : 'slide-panel-closed'}`}
                >
                    <Box className="d-flex justify-content-between align-items-center mb-4">
                        <h4 className="mb-0 pb-0"><LayersIcon style={{fontSize:'27px' }} /> Carte et données</h4>
                        <CloseIcon style={{cursor: 'pointer'}} onClick={toggleWindow}/>
                    </Box>
                    <Box>
                        <Box>
                            <h5>Mes cartes</h5>

                            <Box sx={{display: 'flex', flexWrap: 'wrap', mb:2}}>
                                <Button className={tagsIsVisible ? 'd-block isActived' : 'd-block'} onClick={tagsIsVisible}>
                                    <Box sx={{
                                        width: "80px",
                                        height: "80px",
                                        backgroundImage: 'url(' + MapTagImage + ')',
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        mb: 1
                                    }}></Box>
                                    <p className="pb-0 mb-0">Tag</p>
                                </Button>
                            </Box>
                            {
                                isTagDisplayed && (
                                    <div className="d-flex flex-row flex-wrap gap-2">
                                        {tagsWithParcels.filter(t => t.name.includes(searchValue)).map((tag, index) => (
                                            <button
                                                onClick={() => handleTagClick(tag)}
                                                key={index}
                                                style={{
                                                    backgroundColor: `${tag.bgColorHex}`,
                                                    width: 'fit-content',
                                                    borderRadius: "8px",
                                                    borderWidth: "0px"
                                                }}
                                                className={`px-3 py-1 text-white rounded-md`}
                                            >
                                                {tag.name}
                                            </button>
                                        ))}
                                    </div>
                                )
                            }
                        </Box>
                    </Box>
                </div>
            )}
        </>
    );
})

export default MapData;