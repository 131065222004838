import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import BlockIcon from '@mui/icons-material/Block';
import jwtInterceptor from '../service/jwtInterceptor';
import moment from 'moment/moment';
import { useApplication } from '../hooks/UseApplication';
import { API_URL } from '@utils/constants';

function TableRowUser(props) {
  const { row, keyIndex } = props;
  const { user } = useApplication()

  const deleteUser = (row) => {
    if (row) {
      if (row._source.id != user.id) {
        jwtInterceptor.delete( `${API_URL}/user?id=${row._source.id}`).then(res => {
          window.location.reload(false);
        });
      }
    }

  }

  const tdStyle = {
    padding: '10px',
    textAlign: 'left',
    width: '16.66%',
    borderBottom: '1px solid #dee2e6'
  };

  return (
    <React.Fragment>
      <tr style={{
        backgroundColor: keyIndex % 2 === 0 ? '#fff' : '#f8f9fa',
        width: '100%'
      }}>
        <td style={tdStyle}>
          {row._source.username}
        </td>
        <td style={tdStyle}>
          {row._source.firstname}
        </td>
        <td style={tdStyle}>
          {row._source.email}
        </td>
        <td style={tdStyle}>
          {moment(row._source.createdAt).format('DD-MM-YYYY')}
        </td>
        <td style={tdStyle}>
          {moment(row._source.updatedAt).format('DD-MM-YYYY')}
        </td>
        <td style={tdStyle}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => deleteUser(row)}
          >
            {user.id == row._source.id ? <BlockIcon /> : <DeleteIcon />}
          </IconButton>
        </td>
      </tr>
    </React.Fragment>
  );
}

export default TableRowUser;
