import jwtInterceptor from "../jwtInterceptor";
import {API_ENDPOINT} from "../../utils/api";

class TagService {


    async findAllByConnectedUser(pagination) {
        const response = await jwtInterceptor.get(`${API_ENDPOINT}/crm/tag/by-connected-user`, {params: pagination})

        return response.data;
    }

    async save(tagToSave) {
        try {
            const response = await jwtInterceptor.post(`${API_ENDPOINT}/crm/tag`, tagToSave);

            return response.data;
        }catch(error) {
            const message = error.response?.data?.message;
            if(message) throw new Error(message)
            throw error
        }

    }

    async detachTag(tagId, externalLandsId) {
        try {
            const response = await jwtInterceptor.put(`${API_ENDPOINT}/crm/tag/detach`, {externalLandsId, tagId});
            return response.data.data?.updated === true;
        }catch(error) {
            const message = error.response?.data?.message;
            if(message) throw new Error(message)
            throw error
        }
    }

    async deleteTagById(tagId) {
        try {
            const response = await jwtInterceptor.delete(`${API_ENDPOINT}/crm/tag/${tagId}`);
            return response.data.data?.deleted === true;
        }catch(error) {
            const message = error.response?.data?.message;
            if(message) throw new Error(message)
            throw error
        }
    }
}

export const tagService = new TagService()