import React, { useEffect, useState } from "react";
import proj4 from "proj4";
import { detailparcelleStyles } from "./styles";
import { Box, Link, CircularProgress } from "@mui/material";
import { useApplication } from "../../../hooks/UseApplication";
import { searchLocations } from "../../../service/geoApiService";

const DetailInfos = () => {
  const { details } = useApplication();
  const [modifiedGeoPortaLink, setModifiedGeoPortaLink] = useState("");

  // Définir les projections
  const wgs84 = "EPSG:4326";
  const lv95 = "EPSG:2056";

  const [mn95X, setMn95X] = useState(null);
  const [mn95Y, setMn95Y] = useState(null);
  const [coordsMN95, setCoordsMN95] = useState({ x: null, y: null });
  const [batiments, setBatiments] = useState([]);

  const [solarPotentialUrl, setSolarPotentialUrl] = useState("");
  const [isSolarPotentialLoading, setIsSolarPotentialLoading] = useState(false);
  const [solarPotentialError, setSolarPotentialError] = useState("");

  useEffect(() => {
    if (details?.geo_center?.lat && details?.geo_center?.lon) {
      // Ajouter la définition de la projection LV95 (CH1903+/LV95)
      proj4.defs(
        lv95,
        "+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k=1 +x_0=2600000 +y_0=1200000 +ellps=bessel +datum=ch1903 +units=m +no_defs"
      );

      // Convertir les coordonnées
      const [x, y] = proj4(wgs84, lv95, [
        details.geo_center.lon,
        details.geo_center.lat,
      ]);

      setCoordsMN95({ x, y });

      // Construire le lien avec les coordonnées MN95 arrondies
      if (details.geo_portal_link) {

        const parcelle = '&locparcelle=' + details.code_number;
        const adresseId = '&locadresse=' + details.address_id;
        const centerMark = '&keepgraphic=true&addcrosshair=true';

        setModifiedGeoPortaLink(
          details.geo_portal_link
          .replace("mapresources=AMENAGEMENT", "mapresources=AMENAGEMENT,CADASTRE")
          .replace("scale=750", "scale=500")
          .replace("{x}", Math.round(x))
          .replace("{y}", Math.round(y)) + parcelle + adresseId + centerMark
        );
      }
    }

    if (details?.buildings?.length > 0) {
      const buildingsData = details.buildings.map((batiment) =>
        batiment.buildings_administrative
          ? [...batiment.buildings_administrative]
          : []
      );
      setBatiments(buildingsData);
    }
  }, [details]);

  useEffect(() => {
    console.log("details.buildings:", details?.buildings);
    if (details?.buildings?.length > 0) {
      // Filtrer les bâtiments qui ont des données dans buildings_administrative
      const buildingsData = details.buildings
        .map((batiment) =>
          batiment.buildings_administrative && batiment.buildings_administrative.length > 0
            ? [...batiment.buildings_administrative]
            : null
        )
        .filter(Boolean); // Supprime les entrées nulles
  
      setBatiments(buildingsData);
      console.log("buildingsData filtré:", buildingsData);
    }
  }, [details]);
  

  const calculateSolarPotential = async () => {
    console.log("lat", details);

    if (!details.geo_center.lat || !details.geo_center.lon) {
      setSolarPotentialError("Coordonnées manquantes");
      return;
    }

    setIsSolarPotentialLoading(true);
    setSolarPotentialError("");

    try {
      const result = await searchLocations(
        details.geo_center.lat,
        details.geo_center.lon
      );

      console.log("result >>", result);

      if (result?.featureId) {
        const url = `https://www.uvek-gis.admin.ch/BFE/sonnendach/index.html?featureId=${result.featureId}&lang=fr`;
        setSolarPotentialUrl(url);
      } else {
        setSolarPotentialError("Données d'ensoleillement non disponibles");
      }
    } catch (error) {
      console.error("Erreur lors du calcul du potentiel solaire:", error);
      setSolarPotentialError("Erreur lors de la récupération des données");
    } finally {
      setIsSolarPotentialLoading(false);
    }
  };

  useEffect(() => {
    if (details.id) {
      calculateSolarPotential();
    }
  }, [details.id]);

  const renderSolarPotentialJsx = () => {
    if (isSolarPotentialLoading) {
      return <CircularProgress size={20} sx={{ ml: 1 }} />;
    }

    if (solarPotentialError) {
      return <span style={{ marginLeft: "8px" }}>Indisponible</span>;
    }

    if (solarPotentialUrl) {
      return (
        <Link
          href={solarPotentialUrl}
          target="_blank"
          rel="noopener noreferrer"
          sx={{ ml: 1 }}
        >
          Voir
        </Link>
      );
    }

    return <span style={{ marginLeft: "8px" }}>Aucune donnée</span>;
  };

  const renderBatimentsJsx = () => {
    if (batiments && batiments.length > 0) {
      return batiments.map((batiment, index) => {
        return (
          <Box
            className="pt-4 col-md-4"
            key={batiment?.length > 0 ? batiment[0].id : index}
          >
            <h5 style={detailparcelleStyles.fontWeightTitle}>
              BATIMENT {index + 1}
            </h5>
            <Box
              className="d-flex justify-content-between p-4  "
              style={detailparcelleStyles.grayBg}
            >
              {renderBatimentJsx(batiment)}
            </Box>
          </Box>
        );
      });
    }

    return (
      <Box className="mt-4 p-4" style={detailparcelleStyles.grayBg}>
        Pas de bâtiments disponible
      </Box>
    );
  };

  const renderBatimentJsx = (batimentObj) => {
    // Debug pour voir la structure des données
    console.log("batimentObj:", batimentObj);

    // Vérifier si batimentObj est un tableau et a des éléments
    if (!Array.isArray(batimentObj) || !batimentObj.length) {
      return null;
    }

    // Utiliser le premier bâtiment sans modifier le tableau original
    const batiment = batimentObj[0];
    if (batiment) {
      return (
        <Box className="me-2" style={detailparcelleStyles.cellWdth}>
          <p>
            <span
              style={{
                color: detailparcelleStyles.graylight,
                ...detailparcelleStyles.fontWeightTitle,
              }}
            >
              Classe bâtiments :
            </span>{" "}
            {batiment.category}
          </p>
          <p>
            <span
              style={{
                color: detailparcelleStyles.graylight,
                ...detailparcelleStyles.fontWeightTitle,
              }}
            >
              Année de contruction :
            </span>{" "}
            {batiment.building_period}
          </p>
          <p>
            <span
              style={{
                color: detailparcelleStyles.graylight,
                ...detailparcelleStyles.fontWeightTitle,
              }}
            >
              Numéro Officiel bâtiments :
            </span>{" "}
            {batiment.egid && (
              <Link
                href={`https://www.housing-stat.ch/fr/query/egid.html?egid=${batiment.egid}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ fontStyle: "none" }}
              >
                Voir{" "}
              </Link>
            )}
          </p>
        </Box>
      );
    }
    return null;
  };

  return (
    <Box
      className="Layout bg-white my-4 rounded"
      sx={{
        display: "flex",
        height: "auto",
        flexWrap: "wrap",
        flexDirection: {
          xl: "row",
          lg: "row",
          md: "row",
          sm: "column-reverse",
          xs: "column-reverse",
        },
        borderTopRightRadius: "8px",
        borderTopLeftRadius: { lg: 0, md: 0, sm: "8px", xs: "8px" },
        justifyContent: {
          lg: "inherit",
          md: "inherit",
          sm: "flex-end",
          xs: "flex-end",
        },
      }}
    >
      <Box
        className="container-fluid"
        sx={{
          width: {
            xl: "100%",
            lg: "100%",
            md: "100%",
            sm: "100%",
            xs: "100%",
          },
        }}
      >
        <Box className="row">
          <Box className="col-12 px-4 p-4 pb-0">
            <h4 style={detailparcelleStyles.fontWeightTitle}>INFORMATIONS</h4>
            <Box
              className="d-flex justify-content-between p-4  "
              sx={{...detailparcelleStyles.grayBg , ...detailparcelleStyles.flexWrapMobile}}
            >
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    N° parcelle :
                  </span>{" "}
                  {details.code_number}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Type :
                  </span>{" "}
                  {details.type === "private" ? "Privé" : "Public"}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Type de plan :
                  </span>{" "}
                  {details.main_lupa_type}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Adresse :
                  </span>{" "}
                  {details.address_full}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Commune OFS n<sup>°</sup> :
                  </span>{" "}
                  {details.city_ofs_number}
                </p>
              </Box>
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Surface parcelle :
                  </span>{" "}
                  {details.area} m<sup>2</sup>
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Surface bâtiment :
                  </span>{" "}
                  {details.building_total_area} m<sup>2</sup>
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Etage max :
                  </span>{" "}
                  {details.max_floor_nb}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Hauteur max :
                  </span>{" "}
                  {details.max_height
                    ? `${details.max_height} m`
                    : "Non spécifié"}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Nom Zone :
                  </span>{" "}
                  {details.main_lupa_name}
                </p>
              </Box>
              <Box className="mx-2" sx={detailparcelleStyles.cellWdth}>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    EGRID :
                  </span>{" "}
                  {details.egrid_nb}
                </p>
                {details.rf_uri === "" || (
                  <p>
                    <span
                      style={{
                        color: detailparcelleStyles.graylight,
                        ...detailparcelleStyles.fontWeightTitle,
                      }}
                    >
                      Registre Foncier :
                    </span>

                    {details.rf_uri ? (
                      <Link
                        href={details.rf_uri}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontStyle: "none" }}
                      >
                        Voir
                      </Link>
                    ) : (
                      ""
                    )}
                  </p>
                )}
                {modifiedGeoPortaLink === "" || (
                  <p>
                    <span
                      style={{
                        color: detailparcelleStyles.graylight,
                        ...detailparcelleStyles.fontWeightTitle,
                      }}
                    >
                      Géoportail :
                    </span>

                    {modifiedGeoPortaLink ? (
                      <Link
                        href={modifiedGeoPortaLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        sx={{ fontStyle: "none" }}
                      >
                        Voir
                      </Link>
                    ) : (
                      ""
                    )}
                  </p>
                )}
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Cadastre :
                  </span>

                  {details.cadastreLink ? (
                    <Link
                      href={details.cadastreLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      sx={{ fontStyle: "none" }}
                    >
                      Télécharger
                    </Link>
                  ) : (
                    ""
                  )}
                </p>
                <p>
                  <span
                    style={{
                      color: detailparcelleStyles.graylight,
                      ...detailparcelleStyles.fontWeightTitle,
                    }}
                  >
                    Ensoleillement:
                  </span>
                   
                  {renderSolarPotentialJsx()}
                </p>
              </Box>
            </Box>
          </Box>
          <Box className="row p-4">{renderBatimentsJsx()}</Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DetailInfos;
