import { API_URL } from "./constants";

const API_ENDPOINT = API_URL;
const CRM = API_ENDPOINT + '/crm',

ELASTIC_LAND_BASE_URL = API_ENDPOINT + '/lands';
const ELASTIC_LAND_ENDPOINTS = {
    findById: ELASTIC_LAND_BASE_URL + '/:landId',
    search: ELASTIC_LAND_BASE_URL + '/search'
} 

//land endpoints
const LAND_BASE_URL = CRM + '/lands'; 
const LAND_ENDPOINTS = {
    list: LAND_BASE_URL + '/',
    manage: LAND_BASE_URL + '/manage/:landId',
    isManaged: LAND_BASE_URL + '/is-managed/:landId',
    searchMyLands: LAND_BASE_URL + '/my-lands/search'

};

const CONTACT_BASE_URL = CRM + "/contacts";
const CONTACT_ENDPOINTS = {
    new: CONTACT_BASE_URL + "/",
    search: CONTACT_BASE_URL + "/",
    getById: CONTACT_BASE_URL + "/:contactId",
    getByExternalLandId: CONTACT_BASE_URL + '/land-external-id/:externalLandId'
    
}

const AFFAIRE_BASE_URL = CRM + "/opportunities";
const AFFAIRE_ENDPOINTS = {
    new: AFFAIRE_BASE_URL + "/",
    search: AFFAIRE_BASE_URL + "/",
    delete : AFFAIRE_BASE_URL + '/:opportunityId'
    
}

const TACHE_BASE_URL = CRM + "/task";
const TACHE_ENDPOINTS = {
    new: TACHE_BASE_URL + "/",
    delete: TACHE_BASE_URL + "/:taskId"
}

const NOTE_BASE_URL = CRM + "/note";
const NOTE_ENDPOINTS = {
    new: NOTE_BASE_URL + "/",
    getByContactId: NOTE_BASE_URL + "/contact/:contactId",
    getByLandId: NOTE_BASE_URL + "/land/:landId",
    delete: NOTE_BASE_URL + '/:noteId'
}

const USER_BASE_URL = API_ENDPOINT + "/user";
const USER_ENDPOINTS = {
    getPerPage: USER_BASE_URL
}

const LANDS_INTERACTIONS_BASE_URL = CRM + "/lands-interactions";
const LANDS_INTERACTIONS_ENDPOINTS = {
    updateFavorite : LANDS_INTERACTIONS_BASE_URL + "/update-favorite/:externalId",
    updateArchive : LANDS_INTERACTIONS_BASE_URL + "/update-archive/:externalId",
    attachTags : LANDS_INTERACTIONS_BASE_URL + "/attach-tags",
    getLands : LANDS_INTERACTIONS_BASE_URL + "/lands"
}

const AGGREGATIONS_BASE_URL = API_ENDPOINT + "/aggregation";
const AGGREGATIONS_ENDPOINTS = {
    searchZoneAffectation : AGGREGATIONS_BASE_URL + "/zone-affectation/search",
    searchCommune : AGGREGATIONS_BASE_URL + "/commune/search",
    searchAddresses : AGGREGATIONS_BASE_URL + "/addresses/search",
    searchOwners : AGGREGATIONS_BASE_URL + "/owners/search",
}

const LANDS_LINK_URL = API_ENDPOINT + "/lands-link"
const LANDS_LINK_ENDPOINTS = {
    upsert : LANDS_LINK_URL + "/"
}

const GOV_API_ENDPOINTS = {
    solarPotentialUrl : 'https://www.uvek-gis.admin.ch/BFE/sonnendach/index.html?featureId=:featureId&lang=fr'
}

export {
    API_ENDPOINT, 
    ELASTIC_LAND_ENDPOINTS,
    LAND_ENDPOINTS,
    CONTACT_ENDPOINTS,
    AFFAIRE_ENDPOINTS,
    NOTE_ENDPOINTS,
    TACHE_ENDPOINTS,
    USER_ENDPOINTS,
    LANDS_INTERACTIONS_ENDPOINTS,
    AGGREGATIONS_ENDPOINTS,
    LANDS_LINK_ENDPOINTS,
    GOV_API_ENDPOINTS
};
