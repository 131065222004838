import {Box, Button} from "@mui/material";
import React, {useEffect, useState} from "react";
import {tagService} from "../../service/crm/TagService";
import Form from "react-bootstrap/Form";
import {useToast} from "../../Context/ToastContext";
import { ChromePicker } from 'react-color';

const PRESET_COLORS = [
    '#FF0000', // Rouge
    '#FFA500', // Orange
    '#FFFF00', // Jaune
    '#00FF00', // Vert
    '#00FFFF', // Cyan
    '#0000FF', // Bleu
    '#800080', // Violet
    '#FF00FF', // Magenta
    '#FFC0CB', // Rose
    '#A52A2A', // Marron
    '#808080', // Gris
    '#000000', // Noir
    '#FFFFFF', // Blanc
    '#299CDB', // Bleu Mixdata
    '#4CAF50', // Vert succès
    '#F44336', // Rouge erreur
    '#FF9800', // Orange attention
    '#9C27B0', // Violet
    '#607D8B', // Gris bleuté
    '#795548'  // Marron clair
];

export const CreateTag = ({tag, onTagSaved}) => {
    const [name, setName] = React.useState("");
    const [bgColorHex, setBgColorHex] = React.useState("");
    const [showColorPicker, setShowColorPicker] = useState(false);
    const toaster = useToast();

    const overrideTagValues = (tag) => {
        if(tag) {
            setName(tag.name);
            setBgColorHex(tag.bgColorHex);
        }

    }

    useEffect(() => {
        overrideTagValues(tag)
    }, [tag]);

    const buildTagToSave = () => {
        let tagToSave
        if (tag) {
            tagToSave = {...tag}
        } else tagToSave = {}

        tagToSave.name = name;
        tagToSave.bgColorHex = bgColorHex;

        return tagToSave
    }

    const onSubmit = () => {
        const tagToSave = buildTagToSave();
        tagService.save(tagToSave).then(response => {
                onTagSaved({...tagToSave, _id: response.data.recordId});
            },
            error => {
                console.log('error', error)
                toaster.notifyError(error.message);
            })
    }

    return (
        <form>
            <Form.Group className="mb-4" controlId="tagName">
                <Form.Label column={'sm'}>
                    Nom
                </Form.Label>
                <Form.Control
                    className="input"
                    type="text"
                    placeholder="nom du tag"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
            </Form.Group>

            <Form.Group className="mb-4" controlId="tagColor">
                <Form.Label style={{width: "100%"}} column={'sm'}>
                    Couleur
                </Form.Label>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <div
                        onClick={() => setShowColorPicker(!showColorPicker)}
                        style={{
                            width: '100px',
                            height: '100px',
                            backgroundColor: bgColorHex || '#000',
                            border: '1px solid #ced4da',
                            cursor: 'pointer',
                            borderRadius: '4px'
                        }}
                    />
                    {showColorPicker && (
                        <div style={{
                            position: 'absolute',
                            left: '110%',
                            top: 0,
                            zIndex: 1000
                        }}>
                            <div
                                style={{
                                    position: 'fixed',
                                    top: 0,
                                    right: 0,
                                    bottom: 0,
                                    left: 0
                                }}
                                onClick={(e) => {
                                    if (e.target === e.currentTarget) {
                                        setShowColorPicker(false);
                                    }
                                }}
                            />
                            <ChromePicker
                                color={bgColorHex}
                                onChange={(color) => {
                                    setBgColorHex(color.hex);
                                }}
                                disableAlpha={true}
                                presetColors={PRESET_COLORS}
                                styles={{
                                    default: {
                                        picker: {
                                            boxShadow: '0 2px 10px rgba(0,0,0,0.1)'
                                        }
                                    }
                                }}
                            />
                        </div>
                    )}
                </div>
            </Form.Group>

            <Button
                sx={{
                    background: "#299CDB",
                    color: "#fff",
                    "&:hover": { background: "#299CDB" },
                }}
                onClick={onSubmit}
                disabled={!((name && name.trim() !== "") && (bgColorHex && bgColorHex.trim() !== ""))}
            >
                {(!tag) ? 'Ajouter' : 'Modifier'}
            </Button>
        </form>
    )
}