import { Box, Card, Paper, Typography, Button, IconButton , Collapse } from "@mui/material";
import Form from "react-bootstrap/Form";
import React, { useEffect, useRef, useState } from "react";
import TextField from "@mui/material/TextField";
import RefreshIcon from '@mui/icons-material/Refresh';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import {useLocation} from "react-router-dom";

export const LandsMapFilterCard = ({ handleSearch }) => {
  const location = useLocation()
  const defaultFilters = location.state

  const [landsCodeNumber, setLandsCodeNumber] = useState(defaultFilters?.landsCodeNumber ?? "");
  const [zoneAffectation, setZoneAffectation] = useState(defaultFilters?.zoneAffectation ?? "");
  const [commune, setCommune] = useState(defaultFilters?.commune ?? "");
  const [address, setAddress] = useState(defaultFilters?.address ?? "");
  const [owner, setOwner] = useState(defaultFilters?.owner ?? "");
  const [canton, setCanton] = useState(defaultFilters?.canton ?? "");
  const [areaMin, setAreaMin] = useState(defaultFilters?.areaMin ?? null);
  const [areaMax, setAreaMax] = useState(defaultFilters?.areaMax ?? null);
  const [minYear, setMinYear] = useState(defaultFilters?.minYear ?? null);
  const [maxYear, setMaxYear] = useState(defaultFilters?.maxYear ?? null);
  const [minBuildingNbr, setMinBuildingNbr] = useState(defaultFilters?.minBuildingNbr ?? null);
  const [maxBuildingNbr, setMaxBuildingNbr] = useState(defaultFilters?.maxBuildingYear ?? null);
  const [showAllFields, setShowAllFields] = useState(false);

  useEffect(() => {
    onClickValidate()
  }, []);

  const onClickValidate = () => {
    let newFilters = {};
    let isEmpty = true;
    if (landsCodeNumber) {
      newFilters.landsCodeNumber = landsCodeNumber
      isEmpty = false
    };
    if (commune) {
      newFilters.commune = commune
      isEmpty = false
    };
    if (zoneAffectation) {
      newFilters.zoneAffectation = zoneAffectation;
      isEmpty = false
    }
    if (address) {
      newFilters.address = address
      isEmpty = false

    }
    if (owner) {
      newFilters.owner = owner;
      isEmpty = false

    }
    if (canton) {
      newFilters.canton = canton;
      isEmpty = false

    }
    if (areaMin && areaMax) {
      newFilters.areaMin = areaMin;
      newFilters.areaMax = areaMax;
      isEmpty = false
    }
    if (maxYear) {
        newFilters.maxYear = maxYear;
        isEmpty = false
    }
    if (minYear) {
        newFilters.minYear = minYear
        isEmpty = false
    };
    if (maxBuildingNbr) {
      newFilters.maxBuildingNbr = maxBuildingNbr;
      isEmpty = false
    }
    if (minBuildingNbr) {
      newFilters.minBuildingNbr = minBuildingNbr
      isEmpty = false
    };

    newFilters.isEmpty = isEmpty

    handleSearch(newFilters);
  };

  const onClickClean = () => {
    setCommune("");
    setAreaMin("");
    setAreaMax("");
    setMaxYear("");
    setMinYear("");
    setOwner("");
    setCanton("");
    setAddress("");
    setZoneAffectation("");
    setLandsCodeNumber("");
    setMinBuildingNbr("");
    setMaxBuildingNbr("");

    handleSearch({isEmpty : true});
  };

  return (
    <>
    {/* vertical */}
    <Card
      className="shadow-none"
      style={{ padding: "20px", backgroundColor: "#fff", height: "100%", zIndex:"99999999" }}
    >
      <Box component="form">
        <Form.Group className="my-3" controlId="1" key="landsCodeNumber">
          <TextField
            type="text"
            controlId="1"
            label="N° Parcelle"
            placeholder="N° Parcelle"
            fullWidth
            value={landsCodeNumber}
            onChange={(e) => setLandsCodeNumber(e.target.value)}
            size="small"
          />
        </Form.Group>
        <Form.Group className="my-3" controlId="5" key="Commune">
          <TextField
            type="text"
            controlId="5"
            label="Commune"
            placeholder="Commune"
            fullWidth
            value={commune}
            onChange={(e) => setCommune(e.target.value)}
            size="small"
          />
        </Form.Group>
          <IconButton
            onClick={() => setShowAllFields(!showAllFields)}
            sx={{"&:hover" : {background: "none" }, p: 0}}
          >
            {showAllFields ? <><span style={{fontSize: "14px"}}>Afficher moins</span> <ExpandLessIcon /> </> : <><span style={{fontSize: "14px"}}>Filtre avancé</span><ExpandMoreIcon /></>}
        </IconButton>
        <Collapse in={showAllFields} timeout="auto" unmountOnExit>
          <Form.Group className="my-3" controlId="51" key="Canton">
            <TextField
                type="text"
                controlId="5"
                label="Canton"
                placeholder="Canton"
                fullWidth
                value={canton}
                onChange={(e) => setCanton(e.target.value)}
                size="small"
            />
          </Form.Group>
          <Form.Group className="my-3" controlId="3" key="address">
            <TextField
              type="text"
              controlId="3"
              label="Adresse"
              placeholder="Adresse"
              fullWidth
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              size="small"
            />
          </Form.Group>
          <Form.Group className="my-3" controlId="52" key="zoneAffectation">
            <TextField
              type="text"
              controlId="5"
              label="Zone d'affectation"
              placeholder="Zone d'affectation"
              fullWidth
              value={zoneAffectation}
              onChange={(e) => setZoneAffectation(e.target.value)}
              size="small"
            />
          </Form.Group>
          <Form.Group className="mt-3 mb-2 " controlId="6" key="owner">
            <TextField
              type="text"
              controlId="6"
              label="Propriétaire"
              placeholder="Propriétaire"
              fullWidth
              value={owner}
              onChange={(e) => setOwner(e.target.value)}
              size="small"
            />
          </Form.Group>

          <Form.Group className="my-1" controlId="61" key="building_nbr">
            <Form.Label>Nombre de batiment</Form.Label>
            <Box sx={{display: "flex", justifyContent: "space-between"}}>
              <TextField
                  type="number"
                  controlId="6"
                  label="min"
                  placeholder="min"
                  InputProps={{ inputProps: { min: 1 } }}
                  fullWidth
                  value={minBuildingNbr}
                  onChange={(e) => setMinBuildingNbr(e.target.value)}
                  sx={{ mb: 1, width: "47%" }}
                  size="small"
              />
              <TextField
                  type="number"
                  controlId="6"
                  label="max"
                  placeholder="max"
                  InputProps={{ inputProps: { min: 2 } }}
                  fullWidth
                  value={maxBuildingNbr}
                  onChange={(e) => setMaxBuildingNbr(e.target.value)}
                  sx={{width: "47%"}}
                  size="small"
              />
            </Box>
          </Form.Group>
          <Form.Group className="my-1" controlId="6" key="building_year">
              <Form.Label>Date de construction</Form.Label>
              <Box sx={{display: "flex", justifyContent: "space-between"}}>
                  <TextField
                      type="number"
                      controlId="6"
                      label="min"
                      placeholder="min"
                      InputProps={{ inputProps: { min: 1 } }}
                      fullWidth
                      value={minYear}
                      onChange={(e) => setMinYear(e.target.value)}
                      sx={{ mb: 1, width: "47%" }}
                      size="small"
                  />
                  <TextField
                      type="number"
                      controlId="6"
                      label="max"
                      placeholder="max"
                      InputProps={{ inputProps: { min: 2 } }}
                      fullWidth
                      value={maxYear}
                      onChange={(e) => setMaxYear(e.target.value)}
                      sx={{width: "47%"}}
                      size="small"
                  />
              </Box>
          </Form.Group>
          <Form.Group  controlId="6" key="area">
            <Form.Label>Surface parcelle</Form.Label>
          <Box sx={{display: "flex", justifyContent: "space-between"}}>
            <TextField
              type="number"
              controlId="6"
              label="min"
              placeholder="min"
              InputProps={{ inputProps: { min: 1 } }}
              fullWidth
              value={areaMin}
              onChange={(e) => setAreaMin(e.target.value)}
              sx={{ mb: 1, width: "47%" }}
              size="small"
            />
            <TextField
              type="number"
              controlId="6"
              label="max"
              placeholder="max"
              InputProps={{ inputProps: { min: 2 } }}
              fullWidth
              value={areaMax}
              onChange={(e) => setAreaMax(e.target.value)}
              sx={{width: "47%"}}
              size="small"
            />
            </Box>
          </Form.Group>
        </Collapse>
        
        <Box sx={{ display: "flex", justifyContent: "flex-end", pt: 2 }}>

        <Button
          sx={{
            background: "#757575",
            color: "#fff",
            "&:hover": { background: "#757575" },
            display:"flex",
            alignItems: "center",
            justifyContent: "space-between",
            verticalAlign: "center",
            fontWeight: 500
          }}
          onClick={() => onClickClean()}
        >
          <RefreshIcon sx={{mr: 1, fontSize: 18}} />
          REINITIALISER
        </Button>
        <Button
          sx={{
            background: "#299CDB",
            color: "#fff",
            "&:hover": { background: "#299CDB" },
            ml: 1,
          }}
          onClick={() => onClickValidate()}
        >
          Filtrer
        </Button>
        </Box>
      </Box>
    </Card>
    </>
  );
};
