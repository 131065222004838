import {
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  Box,
  Chip,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import CustomBtn from "../customBtns/CustomBtn";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { Link } from "react-router-dom";
import Modal from "@mui/material/Modal";
import CreateContact from "../detailcrm/modals/creationcontact";
import jwtInterceptor from "../../service/jwtInterceptor";
import {CONTACT_ENDPOINTS} from "../../utils/api";
import { useToast } from "../../Context/ToastContext";
import {Helmet} from "react-helmet";

const ListCrm = () => {
  const [contacts, setContacts] = useState([]);
  const [openCreateContact, setOpenCreateContact] = useState(false);
  const [selectedContact, setSelectedContact] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const toaster = useToast();

  const handleCloseContact = () => {
    setOpenCreateContact(false);
    setSelectedContact(null);
    setIsEditMode(false);
    fetchData();
  };

  const handleContactCreated = (newContact) => {
    // Ajoutez le nouveau contact à la liste
    setContacts((prevContacts) => [...prevContacts, newContact]);
  };

  const handleContactUpdated = (updatedContact) => {
    setContacts((prevContacts) =>
      prevContacts.map((contact) =>
        contact._id === updatedContact._id ? updatedContact : contact
      )
    );
  };

  const handleEditContact = (contact) => {
    setSelectedContact(contact);
    setIsEditMode(true);
    setOpenCreateContact(true);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    jwtInterceptor
      .get(CONTACT_ENDPOINTS.search)
      .then((response) => {
        setContacts(response.data.data.records);
      })
      .catch((error) => {
        toaster.notifyError(error.message);
      });
  };

  

  const handleOpenCreateContact = () => {
    setSelectedContact(null);
    setIsEditMode(false);
    setOpenCreateContact(true);
  };

  // Custom styles
  const boxedStyles = {
    background: "#fff",
    borderRadius: "8px",
    padding: "25px", 
  };

  const tableBodyStyles = {
    height: "auto",
    overflow: "hidden",
  };

  const tableHeadStyles = {
    background: "#F5F5F5",
  };

  const fontWeightMedium = {
    fontWeight: "500!important",
  };

  const fontWeightTitle = {
    fontWeight: "600",
  };

  return (
    <Box sx={boxedStyles} className="my-4 mx-4">
      <Helmet>
        <title>Mes contacts - Mixdata</title>
      </Helmet>
      <Box
       className="mb-4"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 className="mb-0" style={fontWeightTitle}>
          Mes contacts
        </h3>

        <Box>
          <CustomBtn
            label="contact"
            bgColor="#299CDB"
            iconBg={"#3ba9e5"}
            icon={"+"}
            click={handleOpenCreateContact}
          />
          {/* <Image src={FilterIcon} className="fluid ms-3 pointer"></Image> */}
        </Box>
      </Box>
      <Box sx={{ overflow: "auto", maxHeight: 'calc(100vh - 300px)' }}>
        <Table className="crm" size="small">
          <TableHead sx={{ 
            ...tableHeadStyles, 
            position: 'sticky', 
            top: 0,
            zIndex: 1000
          }}>
            <TableRow>
              <TableCell sx={fontWeightMedium}>Contact</TableCell>
              <TableCell sx={fontWeightMedium}>Type</TableCell>
              <TableCell sx={fontWeightMedium}>N° Parcelle</TableCell>
              <TableCell sx={fontWeightMedium}>Nb Affaires</TableCell>
              <TableCell sx={fontWeightMedium}>Nb Notes</TableCell>
              <TableCell sx={fontWeightMedium}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={tableBodyStyles} > 
            {contacts.length > 0 && 
                contacts.map(
                (contact, index) =>
                   (
                    <React.Fragment key={contact.id}>
                        <TableRow
                          sx={{
                            borderTop:
                              "1px solid rgba(224, 224, 224, 1)!important",
                          }}
                        >
                          <TableCell>
                              <Link
                                to={`/detail/${contact.lands[0]._id}/${contact._id}`}
                                style={{ textDecoration: "none" }}
                              >
                                {contact.lastName +
                                    (contact.firstName
                                      ? " " + contact.firstName
                                      : "")}
                              </Link>
                          </TableCell>
                          <TableCell> 
                            {(contact.type) ||
                            (contact.types) ? (
                              <Chip
                                sx={{ background: "#3F50B6", color: "#fff" }}
                                label={
                                  (contact.type) ||
                                  (contact.types)
                                }
                              />
                            ) : (
                              <></>
                            )}
                          </TableCell>
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                minWidth: "100px"
                              }}
                            >
                              {contact.lands[0].resume.code_number} (
                              {contact.lands[0].resume.area &&
                              contact.lands[0].resume.area > 0 ? (
                                <span>
                                  {contact.lands[0].resume.area} m<sup>2 </sup>
                                </span>
                              ) : (
                                ""
                              )}
                              {contact.lands[0].resume.city_name ? (
                                <span>
                                  {" "}
                                  &nbsp;-&nbsp;{contact.lands[0].resume.city_name}
                                </span>
                              ) : (
                                ""
                              )}
                              {contact.lands[0].resume.region_name ? (
                                <span>
                                  {" "}
                                  &nbsp;-&nbsp;{contact.lands[0].resume.region_name}
                                </span>
                              ) : (
                                ""
                              )}
                              )
                            </Box>
                          </TableCell>
                          <TableCell>
                            {contact.opportunities
                              ? contact.opportunities.length
                              : 0}
                          </TableCell>
                          <TableCell>
                            {contact.notes
                              ? contact.notes.length
                              : 0}
                          </TableCell>
                          <TableCell>
                          <ModeEditIcon 
                              sx={{ width: "20px", cursor: "pointer" }} 
                              onClick={() => handleEditContact(contact)}
                            />
                          </TableCell>
                        </TableRow>
                    </React.Fragment>
                  )
              )}
          </TableBody>
        </Table>
        <Modal
          open={openCreateContact}
          onClose={handleCloseContact}
          aria-labelledby="creation-contact-title"
          aria-describedby="creation-contact-description"
        >
          <CreateContact 
          closeIt={handleCloseContact} 
          contact={selectedContact}
          isEditMode={isEditMode}
          onContactCreated={handleContactCreated}
          onContactUpdated={handleContactUpdated}
        />
        </Modal>
      </Box>
    </Box>
  );
};
export default ListCrm;
