import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import CloseIcon from "@mui/icons-material/Close";
import Button from "react-bootstrap/Button";
import {Divider} from "@mui/material";
import {tagService} from "../../service/crm/TagService";
import {CreateTagModal} from "./CreateTagModal";

const modalBackground = {
    "& .modal-content" : {
        background: "#fff!important"
    }
}

export const blueLight = "#299CDB";

const validateBtn = {
    background: blueLight,
    height: "40px",
    width: "auto",
    borderRadius: "8px",
    border: "none",
};

const fontWeightTitle = {
    fontWeight: "600",

};



export const AttachTagModal = ({open, onClose, onValidateTags}) => {
    const [openCreateTagModal, setOpenCreateTagModal] = useState(false);
    const [newTag, setNewTag] = useState("");
    const [allTags, setAllTags] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [selectedTags, setSelectedTags] = useState([]);
    const [isValidating, setIsValidating] = useState(false);

    useEffect(() => {
        fetchAllTags()
    }, [])

    const fetchAllTags = () => {
        tagService.findAllByConnectedUser().then(response => {
            console.log('resposne', response)
            setAllTags(response.data.records)
        })
    }

    const onNewTagAdded = (newTag) => {
        setOpenCreateTagModal(false)
        setAllTags([...allTags, newTag]);
    }

    const handleValidate = async () => {
        if (isValidating) return;
        setIsValidating(true);
        const lastSelectedTags = [...selectedTags.map(t => t._id)];
        console.log('Starting validation with tags:', lastSelectedTags);
        try {
            await new Promise((resolve) => {
                onValidateTags(lastSelectedTags);
                setTimeout(resolve, 500);
            });
            console.log('Validation completed successfully');
            setSelectedTags([]);
        } catch (error) {
            console.error('Error validating tags:', error);
        } finally {
            setIsValidating(false);
        }
    }

    return (
        <>
            <Modal size="lg" show={open} style={Object.assign(
                modalBackground
            )}>
                <Modal.Header style={{justifyContent: "space-between" , alignItems: "center"}}>
                    <span style={{display: "flex", alignItems: "center"}}>
                        <Modal.Title
                            className="d-flex align-items-center"
                            id="contained-modal-title-vcenter"
                        >
                            <span>Gestion tag</span>
                        </Modal.Title>
                    </span>
                    <CloseIcon className="pointer" onClick={onClose}/>
                </Modal.Header>
                <Modal.Body>
                    <div className="bg-white p-6 rounded-lg max-w-md w-full">
                        <div className={"d-flex justify-content-between mb-3"}>
                            <input
                                type="text"
                                placeholder="Rechercher un tag"
                                value={searchValue}
                                onChange={(e) => setSearchValue(e.target.value)}
                                className="w-50 p-2 border rounded"
                            />
                            <div className={"w-50 d-flex justify-content-end"}>
                                <Button
                                    onClick={() => setOpenCreateTagModal(true)}
                                    style={{...validateBtn, ...fontWeightTitle}}
                                >
                                    + Créer un nouveau tag
                                </Button>
                            </div>
                        </div>
                        <div className="d-flex flex-row flex-wrap gap-2">
                            {allTags.filter(t => t.name.includes(searchValue)).map((tag, index) => (
                                    <button
                                        onClick={() => {
                                            if (!selectedTags.some(t => t._id === tag._id)) {
                                                setSelectedTags([...selectedTags, tag])
                                            }
                                        }}
                                        key={index}
                                        style={{
                                            backgroundColor: `${tag.bgColorHex}`,
                                            width: 'fit-content',
                                            borderRadius: "8px",
                                            borderWidth: "0px"
                                        }}
                                        className={`px-3 py-1 text-white rounded-md`}
                                    >
                                        {tag.name}
                                    </button>
                                )
                            )}
                        </div>
                        <Divider orientation="horizontal" variant="middle" flexItem
                                 sx={{marginBlock: "15px", marginInline: "0", backgroundColor: "#dee2e6"}}/>
                        <div className={"d-flex justify-content-between mb-1"}>
                            <span>Tags selectionnés</span>
                            <Button
                                onClick={handleValidate}
                                disabled={isValidating || selectedTags.length === 0}
                                style={{...validateBtn, ...fontWeightTitle}}
                            >
                                {isValidating ? 'Validation...' : 'Valider'}
                            </Button>
                        </div>
                        <div className="d-flex flex-row flex-wrap gap-2">
                            {selectedTags.map((tag, index) => (
                                    <button
                                        onClick={() => {
                                            setSelectedTags(selectedTags.filter(t => t._id !== tag._id))
                                        }}
                                        key={index}
                                        style={{
                                            backgroundColor: `${tag.bgColorHex}`,
                                            width: 'fit-content',
                                            borderRadius: "8px",
                                            borderWidth: "0px"
                                        }}
                                        className={`px-3 py-1 text-white rounded-md`}
                                    >
                                        {tag.name}
                                    </button>
                                )
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <CreateTagModal
                open={openCreateTagModal}
                onTagSaved={onNewTagAdded}
                onClose={() => setOpenCreateTagModal(false)}
            />
        </>
    );
}
