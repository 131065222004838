import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const StickyHeader = ({ children, className = '', type = 'header' }) => {
  const headerRef = useRef(null);

  useEffect(() => {
    const header = headerRef.current;
    if (!header) return;

    const handleScroll = () => {
      const headerRect = header.getBoundingClientRect();
      const parentRect = header.parentElement.getBoundingClientRect();
      
      if (parentRect.top <= 0) {
        header.style.position = 'fixed';
        header.style.top = '0';
        header.style.left = `${parentRect.left}px`;
        header.style.right = `${window.innerWidth - parentRect.right}px`;
        header.style.backgroundColor = 'white';
        header.style.zIndex = '1000';
        header.style.padding = type === 'thead' ? '0' : '10px 20px';
        header.style.boxShadow = '0 2px 4px rgba(0,0,0,0.1)';
        
        if (type === 'thead') {
          header.style.width = `${parentRect.width}px`;
          header.style.display = 'table';
          header.style.tableLayout = 'fixed';
        }
      } else {
        header.style.position = 'relative';
        header.style.top = 'auto';
        header.style.left = 'auto';
        header.style.right = 'auto';
        header.style.backgroundColor = 'transparent';
        header.style.boxShadow = 'none';
        header.style.padding = type === 'thead' ? '0' : '10px 0';
        header.style.width = 'auto';
        header.style.display = type === 'thead' ? 'table-header-group' : 'flex';
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [type]);

  return (
    <Box 
      ref={headerRef}
      className={`${type === 'thead' ? 'sticky-thead' : 'd-flex justify-content-between align-items-center mb-2'} ${className}`}
      sx={{
        transition: 'all 0.3s ease',
        ...(type === 'thead' && {
          display: 'table-header-group',
          width: '100%'
        })
      }}
    >
      {children}
    </Box>
  );
};

export default StickyHeader; 