import React from "react";
import { useApplication } from "../../../hooks/UseApplication";
import MapsLayout from "../../MapsLayout";
import { detailparcelleStyles } from "./styles";
import { Box, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

/**** Svgs && icons */
import TimerIcon from "@mui/icons-material/Timer";
import { SquareFoot } from "@mui/icons-material";
import { Apartment } from "@mui/icons-material";
import { Terrain } from "@mui/icons-material";
import MapIcon from "@mui/icons-material/Map";

const DetailMap = () => {
  const { details } = useApplication();
  const navigate = useNavigate();
  const resumeStyle = {
    width: { xl: "100%", lg: "100%", md: "100%", sm: "100%", xs: "100%" },
    borderRadius: "8px",
    // filter: "drop-shadow(0px 4px 4px rgba(230, 230, 230, 0.25))",
    display: "flex",
    justifyContent: "center",
    background: "white",
    flexWrap: "wrap",
    paddingInline: {xl:"20px" , lg: "0px" , md: "0px" , sm: "0px"}
  };
  const responsiveMarginLayout = {
    mt: "45px",
    "@media(max-width: 1096px)": {
      mt: "100px",
    },
    "@media(max-width: 899px)": {
      mt: "130px",
    },
    "@media(max-width: 541px)": {
      mt: "210px",
    },
    "@media(max-width: 375px)": {
      mt: "310px",
    },
  };

  const handleViewMap = () => {
    const searchParams = {
      landsCodeNumber: details.code_number || "",
      address: details.address_full || "",
      isEmpty: false
    };

    navigate('/map', {
      state: searchParams,
      replace: true
    });
  };

  return (
      <Box className="col-lg-12 px-4 pb-3" sx={responsiveMarginLayout}>
        <Box
            sx={{ position: "relative", mb: { xl: 0, lg: 0, md: 0, sm: 0, xs: 0 } }}
        >
          <Box className="" style={detailparcelleStyles.mapsLayout}>
            <MapsLayout
                lat={details.geo_center ? details.geo_center.lat : 0}
                long={details.geo_center ? details.geo_center.lon : 0}
                name={details.address_full || ""}
            />
          </Box>
          <Box sx={{ position: "absolute", top: "10px", right: "10px", zIndex: 1 }}>
            <Button
                variant="contained"
                startIcon={<MapIcon />}
                onClick={handleViewMap}
                sx={{
                  backgroundColor: "white",
                  color: "black",
                  "&:hover": {
                    backgroundColor: "#f5f5f5",
                  },
                }}
            >
              Biens approximités
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between",  mt: "10px " }}>
          <Box sx={resumeStyle}>
            <Box
                className="col-12 col-lg-6 col-md-6 col-sm-12 col-xl col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
                sx={{
                  borderRight: {
                    xl: "2px solid rgba(217, 217, 217, 0.35)",
                    lg: "2px solid rgba(217, 217, 217, 0.35)",
                    md: "2px solid rgba(217, 217, 217, 0.35)",
                    sm: "2px solid rgba(217, 217, 217, 0.35)",
                    xs: "none",
                  },
                  borderBottom: {
                    xl: "inherit",
                    lg: "inherit",
                    md: "inherit",
                    sm: "inherit",
                    xs: "none",
                  },
                }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                  <SquareFoot
                      className="fluid"
                      style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                  />
                  <p
                      className="w-100 text-left mb-0  ms-2 me-2"
                      style={{
                        ...detailparcelleStyles.fontWeightTitle,
                        ...detailparcelleStyles.textBlueLight,
                      }}
                  >
                    Surface :
                  </p>
                </Box>
                <p
                    className=" text-left mb-0 inline-block w-auto fs-6"
                    style={detailparcelleStyles.fontWeightTitle}
                >
                  {details.area} m
                  <sup style={detailparcelleStyles.fontWeightTitle}>2</sup>
                </p>
              </Box>
            </Box>
            <Box
                className="col-12 col-lg-6 col-md-6 col-sm-12 col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
                sx={{
                  borderRight: {
                    xl: "2px solid rgba(217, 217, 217, 0.35)",
                    lg: "none",
                    md: "none",
                    sm: "none",
                    xs: "none",
                  },
                  borderBottom: {
                    xl: "inherit",
                    lg: "inherit",
                    md: "inherit",
                    sm: "inherit",
                    xs: "none",
                  },
                }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                  <TimerIcon
                      className="fluid"
                      style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                  />
                  <p
                      className="w-100 text-left mb-0  ms-2 me-2"
                      style={{
                        ...detailparcelleStyles.fontWeightTitle,
                        ...detailparcelleStyles.textBlueLight,
                      }}
                  >
                    Années :
                  </p>
                </Box>
                <p
                    className=" text-left mb-0 inline-block w-auto fs-6"
                    style={detailparcelleStyles.fontWeightTitle}
                >
                  {details.average_building_year}
                </p>
              </Box>
            </Box>
            <Box
                className="col-12 col-lg-6 col-md-6 col-sm-12 col-xl d-flex justify-content-center flex-wrap  py-0 px-2"
                sx={{
                  borderRight: {
                    xl: "2px solid rgba(217, 217, 217, 0.35)",
                    lg: "2px solid rgba(217, 217, 217, 0.35)",
                    md: "2px solid rgba(217, 217, 217, 0.35)",
                    sm: "2px solid rgba(217, 217, 217, 0.35)",
                    xs: "none",
                  },
                }}
            >
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                  <Apartment
                      className="fluid"
                      style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                  />
                  <p
                      className="w-100 text-left mb-0  ms-2 "
                      style={{
                        ...detailparcelleStyles.fontWeightTitle,
                        ...detailparcelleStyles.textBlueLight,
                      }}
                  >
                    Bâtiment(s) :
                  </p>
                </Box>
                {details.buildings?.length && (
                    <p
                        className=" text-left mb-0 ms-2 inline-block w-auto fs-6"
                        style={detailparcelleStyles.fontWeightTitle}
                    >
                      {details.buildings.length}
                    </p>
                )}
              </Box>
            </Box>
            <Box className="col-12 col-lg-6 col-md-6 col-sm-12 col-xl d-flex justify-content-center flex-wrap  py-0 px-2">
              <Box sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" , width: "auto"}}>
                  <Terrain
                      className="fluid"
                      style={{ ...detailparcelleStyles.logoWidth, fontSize: 30 }}
                  />
                  <p
                      className="w-100 text-left mb-0 ms-2 me-2"
                      style={{
                        ...detailparcelleStyles.fontWeightTitle,
                        ...detailparcelleStyles.textBlueLight,
                      }}
                  >
                    Région :
                  </p>
                </Box>
                <p
                    className=" text-left mb-0 inline-block w-auto fs-6"
                    style={detailparcelleStyles.fontWeightTitle}
                >
                  {details.region_name}
                </p>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
  );
};

export default DetailMap;
