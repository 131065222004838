export class StyleSwitcherControl {
    currentStyle
    onAdd(map) {
        this._map = map;

        // Create a container element and add Bootstrap's btn-group class for grouping buttons
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl btn-group';

        // Define the available styles
        const styles = [
            { name: 'Standard', url: 'mapbox://styles/mapbox/standard' },
            { name: 'Satellite', url: 'mapbox://styles/mapbox/satellite-v9' },
            { name: 'Streets', url: 'mapbox://styles/mapbox/streets-v11' }
        ];

        // Create a button for each style using Bootstrap button classes
        styles.forEach(style => {
            const button = document.createElement('button');
            button.type = 'button';
            button.textContent = style.name;

            // Apply Bootstrap button classes. Feel free to change 'btn-outline-primary' to any other Bootstrap style.

            // Optionally add some inline spacing if needed
            button.style.margin = '2px';

            // Set up the click event to change the map's style
            button.onclick = () => {
                console.log('currentStyle', this.currentStyle)
                if(style.name !== this.currentStyle) {
                    map.setStyle(style.url);
                }

                this.currentStyle = style.name
            };

            this._container.appendChild(button);
        });

        return this._container;
    }

    onRemove() {
        this._container.parentNode.removeChild(this._container);
        this._map = undefined;
    }
}
