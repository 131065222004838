import Modal from "react-bootstrap/Modal";
import {CreateTag} from "./CreateTag";
import {CloseButton} from "react-bootstrap";
import {useEffect, useState} from "react";

export const CreateTagModal = ({tag, open, onTagSaved, onClose}) => {
    const [title, setTitle] = useState("");

    useEffect(() => {
        setTitle(tag ? "Modifier un tag" : "Créer un nouveau tag")
    }, [tag]);

    return (
        <Modal show={open}>
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
                <CloseButton onClick={onClose}></CloseButton>
            </Modal.Header>
            <Modal.Body>
                <CreateTag tag={tag} onTagSaved={onTagSaved} />
            </Modal.Body>
        </Modal>
    )
}