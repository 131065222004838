import React, {useEffect, useState} from "react";
import {Box} from "@mui/material";
import {LandsTag} from "../tags/LandsTag";
import {DetachTagDialog} from "../tags/DetachTagDialog";

export const AttachedLandsTagList = ({land}) => {
    const [isDetachOpen, setIsDetachOpen] = useState(false);
    const [landsTag,  setLandsTag] = useState([]);
    const [selectedTagId, setSelectedTagId] = useState()

    useEffect(() => {
        setLandsTag(land.landsInteractions?.tags ?? [])
    }, []);

    const onDetachTag = (tagId) => {
        setSelectedTagId(tagId);
        setIsDetachOpen(true);
    }

    const onTagDetachDialogClosed = (isDeleted) => {
        console.log("isDeleted", isDeleted)
        if(isDeleted) {
            console.log('inside isDeleted true')
            setLandsTag(landsTag.filter(t => t._id !== selectedTagId))
            setSelectedTagId(undefined)
        }

        setIsDetachOpen(false);
    }


    return (
        <Box className={"d-flex flex-row flex-wrap gap-2 m-2"}>
            {landsTag
                ? landsTag.map(tag => (
                    <LandsTag
                        key={`ladTag-${land.id}-${tag._id}`}
                        tag={tag}
                        landsExternalId={land.externalLandsId}
                        onDetach={() => onDetachTag(tag._id)} />
                ))
                : ""
            }
            <DetachTagDialog
                landsId={land.id}
                tagId={selectedTagId}
                onClose={(e) => onTagDetachDialogClosed(e)}
                isModalOpen={isDetachOpen}
            ></DetachTagDialog>
        </Box>
    )
}