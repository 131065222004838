import {Helmet} from "react-helmet";
import React, {useState} from "react";
import {Paper} from "@mui/material";
import {TagTable} from "../component/tags/TagTable";
import Button from "react-bootstrap/Button";
import {blueLight} from "../component/tags/AttachTagModal";
import {CreateTagModal} from "../component/tags/CreateTagModal";

export const TagContainer = () => {
    const [needFetch, setNeedFetch] = useState(false);
    const [openCreateTagModal, setOpenCreateTagModal] = useState(false)

    const fontWeightTitle = {
        fontWeight:"600"
    };

    const createTagButtonStyle = {
        background: blueLight,
        height: "40px",
        width: "auto",
        borderRadius: "8px",
        border: "none",
    };

    return (
        <div className="d-flex justify-content-center">
            <Helmet>
                <title>Gérer les Tags</title>
            </Helmet>
            <Paper className="py-4 px-4 rounded shadow-none App-container w-100 m-0">
                <div className={"d-flex flex-row justify-content-between"}>
                    <h3 className='mb-4' style={fontWeightTitle}>Tags</h3>
                    <Button
                        onClick={() => setOpenCreateTagModal(true)}
                        style={{...createTagButtonStyle, ...fontWeightTitle}}
                    >
                        + Créer un tag
                    </Button>
                </div>
                <div className='overflow-auto'>
                    <TagTable needFetch={needFetch}></TagTable>
                </div>
            </Paper>
            <CreateTagModal
                open={openCreateTagModal}
                onTagSaved={(e) => {
                    setOpenCreateTagModal(false)
                    setNeedFetch(!needFetch)
                }}
                onClose={() => setOpenCreateTagModal(false)}
            />
        </div>
)
}